import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import {
  createOpenAIPresentation,
  savePresentation,
  generateOpenAISlide,
  getOpenAIPresentation,
  updateOpenAIImage,
  updateOpenAIText,
  getPresentationsList,
  deletePresentation,
  getTemplates,
  getPrompts,
  createOpenAIPresentationFromDoc,
  removeLogo
} from '../../api/presentationApi';
import { decreaseCredit } from '../../api/scriptsApi';

export interface slidesState {
  tamplates: any[];
  prompts: any[];
  slideList: any;
  presentationId: number;
  loading: boolean;
  loadingPage: boolean;
  openSidebar: boolean;
  savePresentation: string;
  presentationsList: any[];
  presName: string;
  listLength: number;
  slideStatus: string;
  presStyle: any;
  objAzure: any;
  fixSidebar: boolean;
};

const initialState: slidesState = {
  tamplates: [],
  prompts: [],
  slideList: [],
  presentationId: 0,
  loading: false,
  loadingPage: false,
  openSidebar: true,
  savePresentation: '',
  presentationsList: [],
  presName: '',
  listLength: 0,
  slideStatus: '',
  presStyle: {},
  objAzure: {},
  fixSidebar: false,
};

export const getAllTemplates = createAsyncThunk(
  'slides/getAllTemplates',
  async (data: any, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      const {body, token} = data
      const res = await getTemplates(body, token);

      dispatch(endLoading());
      return res;
    } catch (error) {
      dispatch(endLoading());
      console.log(error);
    };
  }
);

export const getAllPrompts = createAsyncThunk(
  'slides/getAllPrompts',
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    dispatch(startLoading());
    try {
      const res = await getPrompts(body, token);
      dispatch(endLoading());
      return res;
    } catch (error) {
      dispatch(endLoading());
      console.log(error);
    };
  }
);

export const addPresentation = createAsyncThunk(
  'slides/addPresentation',
  async (data: any) => {
    const { body, token } = data;
    try {
      await savePresentation(body, token);
    } catch (error) {
      console.log(error);
    };
  }
);

export const getPresentation = createAsyncThunk(
  'slides/getPresentation',
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    dispatch(startLoadingPage());
    dispatch(startLoading());
    let id = 0;
    
    try {
      token && await decreaseCredit(token)
      const res = await createOpenAIPresentation(body, token);
      id = await res?.presentation_id;
      
    } catch (error) {
      dispatch(endLoading());
      dispatch(endLoadingPage());
      console.log(error);
    };

    
    if (id) {
      try {
        let result = {
          status: ''
        } as any;

        const delay = (ms: number) => {
          return new Promise(resolve => setTimeout(resolve, ms))
        }

        const loop = async () => {
          while (result.status !== 'ok') {
            /* code to wait on goes here (sync or async) */
            try {
              result = await getOpenAIPresentation(id, token);

              if (result.message === 'Error') {
                const resTwo = await createOpenAIPresentation(body, token);

                id = await resTwo?.presentation_id;
              } 
            } catch (error) {
              dispatch(endLoading());
              dispatch(endLoadingPage());
              return;
            }
            
            await delay(10000)
          }

          if (result.status === 'ok') {
            dispatch(endLoading());
            dispatch(endLoadingPage());
            dispatch(changeSidebarStatus(false))
            return result
          }
        }

        return loop();
      } catch (error) {
        dispatch(endLoading());
        dispatch(endLoadingPage());
        console.log(error);
      };
    } else {
      dispatch(endLoading());
      dispatch(endLoadingPage());
    }
  }
);

export const getPresentationFromDoc = createAsyncThunk(
  'slides/getPresentationFromDoc ',
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    dispatch(startLoadingPage());
    dispatch(startLoading());
    let id = 0;
    
    try {
      const res = await createOpenAIPresentationFromDoc(body, token);
      id = await res?.id;
      
    } catch (error) {
      dispatch(endLoading());
      dispatch(endLoadingPage());
      console.log(error);
    };

    
    if (id) {
      try {
        let result = {
          status: ''
        } as any;

        const delay = (ms: number) => {
          return new Promise(resolve => setTimeout(resolve, ms))
        }

        const loop = async () => {
          while (result.status !== 'ok') {
            /* code to wait on goes here (sync or async) */
            try {
              result = await getOpenAIPresentation(id, token);

              if (result.message === 'Error') {
                const resTwo = await await createOpenAIPresentationFromDoc(body, token);

                id = await resTwo?.id;
              } 
            } catch (error) {
              dispatch(endLoading());
              dispatch(endLoadingPage());
              return;
            }
            
            await delay(10000)
          }

          if (result.status === 'ok') {
            dispatch(endLoading());
            dispatch(endLoadingPage());
            dispatch(changeSidebarStatus(false))
            return result
          }
        }

        return loop();
      } catch (error) {
        dispatch(endLoading());
        dispatch(endLoadingPage());
        console.log(error);
      };
    } else {
      dispatch(endLoading());
      dispatch(endLoadingPage());
    }
  }
);

export const updateImage = createAsyncThunk(
  'slides/updateImage',
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    dispatch(startLoadingPage());
    dispatch(startLoading());
    try {
      const res = await updateOpenAIImage(body, token);
      setTimeout(() => { 
        dispatch(endLoading());
        dispatch(endLoadingPage());
      }, 5000);
      return res;
    } catch (error) {
      dispatch(endLoading());
      dispatch(endLoadingPage());
      console.log(error);
    };
  }
);

export const updateText = createAsyncThunk(
  'slides/updateText',
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    dispatch(startLoadingPage());
    dispatch(startLoading());
    try {
      const res = await updateOpenAIText(body, token);
      dispatch(endLoading());
      dispatch(endLoadingPage());
      return res;
    } catch (error) {
      dispatch(endLoading());
      dispatch(endLoadingPage());
      console.log(error);
    };
  }
);

export const createSlide = createAsyncThunk(
  'slides/createSlide',
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    dispatch(startLoadingPage());
    dispatch(startLoading());
    try {
      const res = await generateOpenAISlide(body, token);

      setTimeout(() => { 
        dispatch(endLoading());
        dispatch(endLoadingPage());
      }, 5000);
      return res;
    } catch (error) {
      console.log(error);
      dispatch(endLoading());
      dispatch(endLoadingPage());
    };
  }
);

export const deleteBrandLogo = createAsyncThunk(
  'slides/deleteBrandLogo',
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    try {
      await removeLogo(body.id, token);

      return body.id;
    } catch (error) {
      console.log(error);
    };
  }
);


export const regenerateSlide = createAsyncThunk(
  'slides/regenerateSlide',
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    dispatch(startLoadingPage());
    dispatch(startLoading());
    try {
      let res = await updateOpenAIText(body, token);
      if (data?.genImage) {
        const resImage = await updateOpenAIImage(body, token);
        res.image = resImage?.image;
      }

      dispatch(endLoading());
      dispatch(endLoadingPage());
      return res;
    } catch (error) {
      console.log(error);
      dispatch(endLoading());
      dispatch(endLoadingPage());
    };
  }
);

export const getPresentations = createAsyncThunk(
  'slides/getPresentations',
  async (data: any, { rejectWithValue, dispatch }) => {
    const { token, body} = data;
    dispatch(startLoading());
    try {
      const res = await getPresentationsList(body, token);

      dispatch(endLoading());
      return {
        res: res[0],
        length: res[1],
      };
    } catch (error) {
      console.log(error);
      dispatch(endLoading());
    };
  }
);

export const removePresentation = createAsyncThunk(
  'slides/removePresentation',
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    dispatch(deletePres(body?.presentationId));
    try {
      await deletePresentation(body, token);
    } catch (error) {
      console.log(error);
    };
  }
);

export const slidesSlice = createSlice({
  name: 'slides',
  initialState,
  reducers: {
    addSlide: (state, action) => {
      state.slideList = [...state.slideList, action.payload]
    },
    changeSlideText: (state, action) => {
      const newArr = state.slideList?.map((a: any) => {
        if (action.payload?.id === a?.id) {
          return action.payload;
        } else {
          return a;
        }
      })
      state.slideList = newArr;
    },
    changePosition: (state, action) => {
      const { from, to } = action.payload;
      state.slideList.splice(to, 0, state.slideList.splice(from, 1)[0]);
    },
    deleteSlide: (state, action) => {
      const { id } = action.payload;
      const newList = state.slideList.filter((a: any) => a.id !== id)
      state.slideList = newList;
    },
    cleanPresentation: (state) => {
      state.slideList = [];
      state.presStyle = {};
      state.prompts = [];
      state.tamplates = [];
    },
    cleanPrompts: (state) => {
      state.prompts = [];
    },
    setFixSidebarStatus: (state, action) => {
      state.fixSidebar = action.payload;
    },
    changeSidebarStatus: (state, action) => {
      state.openSidebar = action.payload;
    },
    presentationSaveStatus: (state, action) => {
      state.savePresentation = action.payload;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    endLoading: (state) => {
      state.loading = false;
    },
    startLoadingPage: (state) => {
      state.loadingPage = true;
    },
    endLoadingPage: (state) => {
      state.loadingPage = false;
    },
    changeSlideStatus: (state, action) => {
      state.slideStatus = action.payload;
    },
    deletePres: (state, action) => {
      state.presentationsList = state.presentationsList.filter((a: any) => a.id !== action.payload);
      state.listLength = state.listLength - 1;
    },
    updatePres: (state, action) => {
      state.presentationsList = state.presentationsList.map((a: any) => {
        if (a.id === action.payload.id) {
          return {
            ...a,
            title: action.payload.title,
          };
        } else {
          return a;
        }
      });
    },
    updateName: (state, action) => {
      state.presName = action.payload;
    },
    setObjAzure: (state, action) => {
      state.objAzure = action.payload; 
    },
    setPresForEdit: (state, action) => {
      const { slides, default_options, title, id} = action.payload;
      const newSlidesArr = slides?.map((item: any) => {
        return {
          ...item,
          text: item.text.replaceAll("•", "\t")
        }
      });
      console.log(newSlidesArr);
      state.presStyle = default_options;
      state.slideList = newSlidesArr.sort((a: any, b: any) => (a?.position + 1) - (b?.position + 1));
      state.presName = title;
      state.loading = false;
      state.presentationId = id;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPresentation.fulfilled, (state, action: any) => {
        state.presStyle = action.payload?.default_options;
        state.slideList = action.payload.slides.sort((a: any, b: any) => (a?.position + 1) - (b?.position + 1));
        state.presentationId = action.payload.id
      })
      .addCase(getPresentationFromDoc.fulfilled, (state, action: any) => {
        state.slideList = action.payload.slides.sort((a: any, b: any) => (a?.position + 1) - (b?.position + 1));
        state.presName = action?.payload?.template_name;
        state.presentationId = action.payload.id;
        state.presStyle = action.payload?.default_options;
      })
      .addCase(createSlide.fulfilled, (state, action) => {
        state.slideList = [...state.slideList, action.payload].sort((a: any, b: any) => (a?.position + 1) - (b?.position + 1));
        state.slideStatus = 'add';
      })
      .addCase(updateImage.fulfilled, (state, action: any) => {
        state.slideList = state.slideList.map((a: any) => {
          if (a.id === action.payload.id) {
            return {
              ...a,
              image: action.payload.image
            };
          } else {
            return a;
          }
        });
      })
      .addCase(updateText.fulfilled, (state, action: any) => {
        state.slideList = state.slideList.map((a: any) => {
          if (a.id === action.payload.id) {
            return action.payload;
          } else {
            return a;
          }
        });
      })
      .addCase(regenerateSlide.fulfilled, (state, action: any) => {
        state.slideList = state.slideList.map((a: any) => {
          if (a.id === action.payload.id) {
            return action.payload;
          } else {
            return a;
          }
        });
      })
      .addCase(getPresentations.fulfilled, (state, action: any) => {
        state.presentationsList = action.payload.res;
        state.listLength = action.payload.length;
      })
      .addCase(getAllTemplates.fulfilled, (state, action: any) => {
        state.tamplates = action.payload;
      })
      .addCase(getAllPrompts.fulfilled, (state, action: any) => {
        state.prompts = action.payload;
      })
      .addCase(deleteBrandLogo.fulfilled, (state, action: any) => {
        state.slideList = [...state.slideList].map((a: any) => {
          if (a.id === action.payload) {
            return {
              ...a,
              isLogo: false,
            }
          } else return a;
        });
      })
  }
});

export const {
  addSlide,
  changeSlideText,
  changePosition,
  deleteSlide,
  cleanPresentation,
  changeSidebarStatus,
  presentationSaveStatus,
  startLoading,
  endLoading,
  deletePres,
  updatePres,
  updateName,
  startLoadingPage,
  endLoadingPage,
  changeSlideStatus,
  cleanPrompts,
  setObjAzure,
  setPresForEdit,
  setFixSidebarStatus
} = slidesSlice.actions;

export default slidesSlice.reducer;