import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';

import './ColorPicker.scss';

type Props = {
  setStyles: (arg: any) => void,
  styles: any,
  defaultValue: string
}

export default function ColorPicker({ setStyles, styles, defaultValue }: Props) {
  const [color, setColor] = useState(defaultValue || "#9747FF");
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setStyles({...styles, color});
  }, [color]);

  const handleButtonClick = () => {
    setShowPicker(!showPicker);
  };

  const handleColorChange = (newColor: any) => {
    setColor(newColor.hex);
  };

  return (
    <div className="ColorPicker">
      <div className="ColorPicker__color-hex">
        {color}
      </div>
      <button
        className="ColorPicker__color-btn"
        type="button"
        onClick={handleButtonClick}
        style={{
          backgroundColor: color
        }}
      />
      {showPicker && (
        <ChromePicker 
          disableAlpha 
          color={color}
          onChange={handleColorChange}
        />
      )}
    </div>
  )
}
