import React, {useEffect, useState} from 'react';
import WhiteButton from '../../../Buttons/WhiteButton/WhiteButton';
import Plus from '../../../../images/plus.svg';
import ChatListItem from './ChatListItem/ChatListItem';
import './ChatList.scss';
import Loader from '../../../Loader/Loader';
import { RootState } from '../../../../redux/store'; 
import { useDispatch, useSelector } from 'react-redux';  
import {fetchChats, createNewChat, addMessage} from '../../../../redux/slices/trainerBotSlice';
import { getCookie } from '../../../../utils/CookieHelper';
import { changeSidebarStatus } from '../../../../redux/slices/slidesSlice';



interface Chat {
    id: number;
    text: string;
}

export default function ChatList() {

const {loading, chats} = useSelector((state: RootState) => state.trainerBot);  
const token = getCookie('jwtToken');
const dispatch = useDispatch<any>();
const {user} = useSelector((state: RootState) => state.scripts);
 
    useEffect(() => {
        dispatch(fetchChats({email: user?.email, token: token}));
        dispatch(changeSidebarStatus(false));
    }, [dispatch, user?.email, token]);

    // add new chat
    const addNewChat = () => {
        dispatch(createNewChat({email: user.email, token: token, title: 'Training Chat'}));
    } 

   

  return (
    
    <div className="ChatList">
        <div className="ChatList__wrapper">
            <div className="ChatList__button">
             <WhiteButton img={Plus} text="Add New Chat" action={addNewChat} />
            </div>
            <div className="ChatList__row__wrapper">
              { chats && chats.map((chat) => (
                    <div className="ChatList__row" key={chat.id}>
                        <ChatListItem key={chat.id} id={chat.id} text={chat.title} />
                    </div>
                ))}
               
            </div>
        </div>
    </div>
    
  )
}
