import React, { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import arrowUp from '../../../../../images/arrow-up.svg';
import arrowDown from '../../../../../images/arrow-down.svg';
import plus from '../../../../../images/plus.svg';
import { changePosition } from '../../../../../redux/slices/slidesSlice';
import WhiteButton from '../../../../Buttons/WhiteButton/WhiteButton';
import DisableEditor from './DisableEditor/DisableEditor';
import { invertLine } from '../../../../../utils/colorHelper';
import './SlidesList.scss';

type Props = {
  slideList: any[],
  setSelectSlide: (arg: any) => void,
  setRemoveModal: (arg: any) => void,
  setTextareaModal: (arg: string) => void,
  messagesEndRef: any
}

export default function SlidesList({
  slideList,
  setSelectSlide,
  setRemoveModal,
  setTextareaModal,
  messagesEndRef
}: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const [showArrows, setShowArrows] = useState<number | string>('');
  const { presStyle } = useSelector((state: RootState) => state.slides);

  return (
    <div className="SlidesList">
      <div className="SlidesList__wrapper"
        style={{
          overflowY: slideList?.length <= 3 ? 'hidden' : 'scroll',
          paddingRight: slideList?.length <= 3 ? '20px' : '12px',
        }}
        ref={messagesEndRef}
      >
        {slideList?.map((el: any, i: number) => (
          <div
            key={el?.id}
            className="SlidesList__item"
            onMouseEnter={() => setShowArrows(el?.id)}
            onMouseLeave={() => setShowArrows('')}
          >
            <p className="SlidesList__number">
              {i + 1}
            </p>
            <div
              className="SlidesList__page"
              onClick={() => setSelectSlide(el)}
              style={{
                backgroundColor: presStyle?.format_options === 'Bold' ? presStyle?.color_palette : '#fff',
                backgroundImage: presStyle?.background_image ? `url('${presStyle?.background_image}')` : 'none',
              }}
            >
              {((!el?.text && !el?.image) 
              || presStyle?.format_options === "Minimalistic"
              || (presStyle?.format_options === "Modern" && !el?.image)) && (
                <div 
                  className="SlidesList__modern-line"
                  style={{
                    backgroundColor: presStyle?.format_options === "Bold"
                        ? invertLine(presStyle?.color_palette)
                        : presStyle?.color_palette
                  }}
                />
              )}
              {(el?.title) && <div 
                className="SlidesList__block"
                style={{
                  gap: el?.text.includes('\t') ? '0' : '25px',
                  padding: '20px 10px 25px 25px',
                  justifyContent: el?.text ? 'flex-start' : 'center'
                }}
              >
                {(presStyle?.brand_logo && el?.isLogo) && (
                  <div>
                    <img 
                      src={presStyle?.brand_logo} 
                      alt="logo"
                      className="SlidesList__brand-logo"
                    />
                  </div>
                )}
                <DisableEditor selectSlide={el} type={"title"}/>
                <DisableEditor selectSlide={el} type={"text"}/>
              </div>}
              {el?.image && <div className="SlidesList__block SlidesList__block--img">
                {presStyle?.format_options === "Modern" && <div 
                  className="SlidesList__vertical-line"
                  style={{
                    backgroundColor: presStyle?.color_palette
                  }}
                ></div>}
                <img
                  style={{
                    width: el?.text ? '100%' : '50%'
                  }}
                  src={el?.image}
                  alt="img"
                />
              </div>}

              {(showArrows === el?.id && slideList?.length > 1) && (
                <div className="SlidesList__arrows">
                  <div
                    className="SlidesList__arrow"
                    onClick={() => (i !== 0) && dispatch(changePosition({
                      from: i,
                      to: i - 1
                    }))}
                  >
                    <img src={arrowUp} alt="up" />
                  </div>
                  <div
                    className="SlidesList__arrow"
                    onClick={() => (i !== slideList?.length - 1) && dispatch(changePosition({
                      from: i,
                      to: i + 1
                    }))}
                  >
                    <img src={arrowDown} alt="down" />
                  </div>
                </div>
              )}

              {(showArrows === el?.id && slideList?.length > 1) && (
                <div
                  className="SlidesList__delete-icon"
                  onClick={() => setRemoveModal(true)}
                >
                  <CloseIcon
                    sx={{
                      fontSize: 13,
                      fontWeight: 'bold',
                      color: '#5A6861'
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
        <div className="SlidesList__slides-add-btn">
          <WhiteButton 
            text={"Add new slide"} 
            img={plus}
            action={() => setTextareaModal("createSlide")}
          />
        </div>
      </div>
    </div>
  )
}
