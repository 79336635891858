import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import slidesReducer from './slices/slidesSlice';
import scriptsReducer from './slices/scriptsSlice';
import trainerBotSlice from './slices/trainerBotSlice';
import { combineReducers } from 'redux';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';

export const rootReducer = combineReducers({
  slides: slidesReducer,
  scripts: scriptsReducer,
  trainerBot: trainerBotSlice,
});

const persistConfig = {
  key: 'slides',
  storage,
  blacklist: []
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer ,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch