import { exportPdf, sendOneDrive } from "../api/azureApi";
import { createDoc, createSlide, customizeDoc } from "../api/googleApi";
import { refreshGoogle } from "../api/presentationApi";
import { setExportError } from "../redux/slices/scriptsSlice";
import { getCookie } from "./CookieHelper";

const refreshToken = async () => {
  
  const key = getCookie('jwtToken');

  if (key) {
    const token = await refreshGoogle(key);
    return token;
  }

  return '';
};

export const sendDoc = async (name: string, type: string, doc: any) => {
  const key = getCookie('jwtToken');
    if(!key){
      return;
    }

    const data = await refreshGoogle(key);
    if(!data){
      return;
    }
    
    const token = data.access_token;
  let script = {} as any;

    if (token) {
      try {
        script = await createDoc(
          token,
          {
            title: name
          }
        );
      } catch (error) {
        refreshToken();
      }
    };

  
  if (script && doc) {
    let body = { "requests": [] } as any;

    if (doc.style) {
      const text = JSON.parse(doc.style)?.blocks?.map((block: any, i: number) => block?.text).join('\n');

      body.requests = [...body?.requests,
        {
          "insertText": {
            "text": text,
            "location": {
              "index": 1
            }
          }
        }
      ];

      let textLength = 0;

      JSON.parse(doc.style)?.blocks?.forEach((block: any, i: number) => {
        if (block.inlineStyleRanges.length > 0) {
          block.inlineStyleRanges.forEach((style: any) => {
            console.log(style.style)
            const styleObj = [] as any;

            if (block?.type === 'unordered-list-item') {
              styleObj.push({
                "createParagraphBullets": {
                  "range": {
                    "startIndex": textLength + style.offset + 1,
                    "endIndex": textLength + style.offset + style.length + 1,
                  },
                  "bulletPreset": "BULLET_DISC_CIRCLE_SQUARE"
                }
              });
            };

            if (style.style === 'BOLD') {
              styleObj.push({
                "updateTextStyle": {
                  "textStyle": {
                    "bold": true,
                  },
                  "fields": "bold",
                  "range": {
                    "startIndex": textLength + style.offset + 1,
                    "endIndex": textLength + style.offset + style.length + 1,
                  },
                },
              });
            }

            if (style.style === 'ITALIC') {
              styleObj.push({
                "updateTextStyle": {
                  "textStyle": {
                    "italic": true,
                  },
                  "fields": "italic",
                  "range": {
                    "startIndex": textLength + style.offset + 1,
                    "endIndex": textLength + style.offset + style.length + 1,
                  },
                },
              });
            };

            if (style.style === 'UNDERLINE') {
              styleObj.push({ 
                "updateTextStyle": {
                  "textStyle": {
                    "underline": true,
                  },
                  "fields": "underline",
                  "range": {
                    "startIndex": textLength + style.offset + 1,
                    "endIndex": textLength + style.offset + style.length + 1,
                  },
                },
              });
            };

            if (style.style.includes('fontsize') || style.style.includes('fontSize')) {
              const fontSize = style.style.split('-')[1];
              styleObj.push({
                "updateTextStyle": {
                  "textStyle": {
                    "fontSize": {
                      "magnitude": fontSize || 16,
                      "unit": "PT",
                    },
                  },
                  "fields": "fontSize",
                  "range": {
                    "startIndex": textLength + style.offset + 1,
                    "endIndex": textLength + style.offset + style.length + 1,
                  },
                },
              });
            };

            if (style.style.includes('color')) {

              styleObj.push({
                "updateTextStyle": {
                  "textStyle": {
                    "foregroundColor": {
                      "color": {
                        "rgbColor": {
                          "red": style.style.includes("color") ? +style.style.replace("color-rgb(", "").split(",")[0] / 255 : 0,
                          "green": style.style.includes("color") ? +style.style.replace("color-rgb(", "").split(",")[1] / 255 : 0,
                          "blue": style.style.includes("color") ? +style.style.replace(")", "").split(",")[2] / 255 : 0
                        }
                      }
                    }
                  },
                  "fields": "foregroundColor",
                  "range": {
                    "startIndex": textLength + style.offset + 1,
                    "endIndex": textLength + style.offset + style.length + 1,
                  }
                }
              });
            };

            if (style.style.includes("fontfamily")) {
              const fontFamily = style.style.split("-")[1];

              styleObj.push({
                "updateTextStyle": {
                  "textStyle": {
                    "weightedFontFamily": {
                      "fontFamily": fontFamily || "Montserrat",
                      "weight": 400,
                    },
                  },
                  "fields": "weightedFontFamily",
                  "range": {
                    "startIndex": textLength + style.offset + 1,
                    "endIndex": textLength + style.offset + style.length + 1,
                  },
                },
              });
            };

            body.requests = [...body?.requests, styleObj];
          });
        }

        let alignObj = {} as any;

        if (block?.data) {
          alignObj = {
            "updateParagraphStyle": {
              "range": {
                "startIndex": textLength + 1,
                "endIndex": textLength + 1,
              },
              "paragraphStyle": {
                "alignment": block?.data["text-align"] === 'center' ? 'CENTER' : (block?.data["text-align"] === 'right' ? 'END' : 'START')
              },
              "fields": "alignment",
            },
          }
        } else {
          alignObj = {
            "updateParagraphStyle": {
              "range": {
                "startIndex": textLength + 1,
                "endIndex": textLength + 1,
              },
              "paragraphStyle": {
                "alignment": "START",
                "fields": "alignment",
              },
            }
          }
        }

        body.requests = [...body?.requests, alignObj];

        if (block.text.length > 0) {
          textLength += block.text.length + 1;
        } else {
          textLength += 1;
        }
      });
      
    } else {
      body.requests = [
        {
          "insertText": {
            "text": doc.text,
            "location": {
              "index": 1
            }
          }
        },
        {
          "updateParagraphStyle": {
            "range": {
              "startIndex": 1,
              "endIndex": 1,
            },
            "paragraphStyle": {
              "alignment": "START",
            },
            "fields": "alignment",
          },
        },
        {
          "updateTextStyle": {
            "textStyle": {
              "bold": false,
              "italic": false,
              "fontSize": {
                "magnitude": 16,
                "unit": "PT",
              },
            },
            "fields": "bold,italic,fontSize",
            "range": {
              "startIndex": 1,
              "endIndex": doc.text?.length,
            },
          },
        },
      ];
    }

    if (type === 'send') {
      script && await customizeDoc(token, script?.documentId, body);
      script?.documentId && window?.open(`https://docs.google.com/document/d/${script?.documentId}/edit`, '_blank')?.focus();
    } else if (type === 'Pdf') {
      script && await customizeDoc(token, script?.documentId, body);
      if (script) {
        try {
          const res = await exportPdf(key, {
            presentationId: script?.documentId,
            isDoc: true
          });

          console.log(res);
          const blob = await res.blob();

          const url = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.download = 'document.pdf';
          link.click();
          URL.revokeObjectURL(url);
        } catch(error: any) {
          return error.response.data.message;
        }
      }
    } else {
      script && await customizeDoc(token, script?.documentId, body);
      if (script) {
        await sendOneDrive(key, {
          presentationId: script?.documentId,
          isDoc: true
        });
      }
    }
  }
}