import React, { useState, useEffect } from 'react';
import { ContentBlock, ContentState, convertFromRaw, convertToRaw, EditorState, CharacterMetadata, genKey } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { changeSlideText } from '../../../../../../redux/slices/slidesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { hexToRgb, invertColor } from '../../../../../../utils/colorHelper';
import { AppDispatch, RootState } from '../../../../../../redux/store';
import { List, Repeat } from 'immutable';
import './DisableEditor.scss';

type Props = {
  selectSlide: any,
  type: 'title' | 'text',
};

export default function DisableEditor({ selectSlide, type }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const { presStyle, slideList} = useSelector((state: RootState) => state.slides);
  // const listItems = window.document.querySelectorAll('li');

  // useEffect(() => {
  //   if (listItems) {
  //     setTimeout(() => {
  //     listItems.forEach((item) => {
  //       const dataOffset = item.getAttribute('data-offset-key');
  //       const spanElement = document.querySelector(`li span[data-offset-key="${dataOffset}"]`) as HTMLElement;
  //       if (spanElement) {
  //         item.style.color = spanElement.style.color;
  //       }
  //     });
  //   }, 300);
  //   }
  // }, [listItems, selectSlide]);

  const [editorState, setEditorState] = useState<EditorState>(
    () => EditorState.createEmpty()
  );

  const createState = (t: string) => {
    return EditorState.createWithContent(ContentState.createFromText(t));
  };

  useEffect(() => {
    if (type === "title") {
      if (selectSlide?.titleStyle) {
        setEditorState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(selectSlide?.titleStyle))));
      } else {
        setEditorState(createState(selectSlide?.title));
      }
    } else {
      if (selectSlide?.textStyle) {
        setEditorState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(selectSlide?.textStyle))));
      } else {
        const contentArray = selectSlide?.text?.split('\n');
        const slideTextArray = contentArray.map((item: string, index: number) => { 
          return new ContentBlock({
            key: genKey(),
            type: item.includes('\t') ? 'unordered-list-item' : 'unstyled',
            //@ts-ignore
            characterList: new List(Repeat(CharacterMetadata.create(), item.length)),
            text: item,
          });
        });
        setEditorState(() => EditorState.createWithContent(ContentState.createFromBlockArray(slideTextArray)));
      }
    };
  }, [selectSlide]);

  useEffect(() => {
    if (!selectSlide?.titleStyle && presStyle) {
      let content: any = {
        "entityMap": {},
        "blocks": [{
          "key": "637gr",
          "characterList": [],
          "text": `${selectSlide?.title.trim()}`,
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [
            {
              "style": 'fontfamily-Montserrat',
              "offset": 0,
              "length": selectSlide?.title?.length
            },
            {
              "style": selectSlide?.position ? 'fontsize-30' : 'fontsize-48',
              "offset": 0,
              "length": selectSlide?.title?.length
            },
            {
              "style": presStyle?.text_color ? `${hexToRgb(presStyle?.text_color)}`
                : (presStyle?.format_options === 'Bold' 
                  ? `${invertColor(presStyle?.color_palette)}` 
                  : `${hexToRgb(presStyle?.color_palette)}`),
              "offset": 0,
              "length": selectSlide?.title?.length
            },
            {
              "style": 'BOLD',
              "offset": 0,
              "length": selectSlide?.title?.length
            }
          ],
          "data": { 
            "text-align": !selectSlide?.position 
                  || (presStyle?.format_options === 'Minimalistic' 
                    && selectSlide?.position !== 0 
                    && selectSlide?.position !== 1 
                    && selectSlide?.position !== slideList.length - 1)
              ? 'center'
              : 'left' }
        }]
      };

      const contentState = convertFromRaw(content);

      let slideTextArray = [] as any
      if (type === "text" && selectSlide?.text?.length) {
        let textTrim = selectSlide?.text;
        if (textTrim.slice(0, 2).includes('\n')) {
          textTrim = selectSlide?.text.replace('\n', '').trim();
        } else {
          textTrim = selectSlide?.text.trim();
        };

        const contentArray = textTrim.split('\n');
        
        slideTextArray = contentArray?.map((item: string, index: number) => {
            return new ContentBlock({
              key: genKey(),
              type: (item.includes('\t')
                || ((index === 0 && selectSlide?.position > 1 && contentArray.length > 1 && selectSlide?.title !== 'Conclusion')
                  && !item.includes('Action Items:')))
                ? 'unordered-list-item'
                : 'unstyled',
              //@ts-ignore
              characterList: new List(Repeat(CharacterMetadata.create(), item.length)),
              depth: 0,
              text: item.replace('\t', '').trim().replaceAll('(;)', '').replaceAll('\n\n', '\n').replaceAll('( )', ''),
            });
        });
      };

      const textObj = {
        ...selectSlide,
        titleStyle: JSON.stringify(convertToRaw(EditorState.createWithContent(contentState).getCurrentContent())),
        textStyle: JSON.stringify({...JSON.parse(JSON.stringify(convertToRaw(EditorState.createWithContent(ContentState.createFromBlockArray(slideTextArray)).getCurrentContent()))),
          blocks: JSON.parse(JSON.stringify(convertToRaw(EditorState.createWithContent(ContentState.createFromBlockArray(slideTextArray)).getCurrentContent()))).blocks?.map((a: any, i: number) => {
          return {
            ...a,
            inlineStyleRanges: a?.text?.length ? [
              { 
                offset: 0, 
                length: JSON.parse(JSON.stringify(convertToRaw(EditorState.createWithContent(ContentState.createFromBlockArray(slideTextArray)).getCurrentContent()))).blocks[i]?.text?.length, 
                style: presStyle?.text_color ? `${hexToRgb(presStyle?.text_color)}` : (presStyle?.format_options === 'Bold' ? `${invertColor(presStyle?.color_palette)}` : `${hexToRgb('#000000')}`),
              }
            ] : [],
          }
        })}),
      };
      dispatch(changeSlideText(textObj));
    }
  }, [selectSlide?.title, selectSlide?.text, presStyle]);

  return (
    <div
      id={`${selectSlide?.id}-${type}`}
    >
      <Editor
        editorState={editorState}
        wrapperClassName='disable-wrapper'

        editorClassName={`${selectSlide?.text ? 'disable-editor disable-editor--left' : 'disable-editor disable-editor--center'} disable-editor--${type}`}
        toolbarClassName='disable-toolbar'
        readOnly={true}
      />
    </div>
  )
}