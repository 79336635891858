import React, { useEffect, useState } from 'react';

import arrow from '../../../../../images/chevron-down.svg';
import './Dropdown.scss';
import classNames from 'classnames';
import { getImageSettings } from '../../../../../api/presentationApi';

type Props = {
  setStyles: (arg: any) => void,
  styles: any,
  defaultValue: string
}

// const buisnesList = ["Business Setting", "Business People", "Nature", "Artistic Painting"];

export default function Dropdown({ setStyles, styles, defaultValue }: Props) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [buisnesList, setBuisnesList] = useState(["None"]);
  const [selectedOption, setSelectedOption] = useState(defaultValue || buisnesList[0]);


  useEffect(() => {
    setStyles({...styles, imageStyle: selectedOption })
  }, [selectedOption]);

  useEffect(() => {
    const fetchImageSettings = async () => {
      const imageSettings = await getImageSettings();
      setBuisnesList(imageSettings.map((elem: any) => elem.title));
    }

    fetchImageSettings();
  }, []);

  return (
    <div className="Dropdown">
      <div 
        className="Dropdown__dropdown-label"
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        {selectedOption}
        <img
          src={arrow} 
          alt="arrow"
          className="Dropdown__dropdown-icon"
        />
      </div>
      {openDropdown && (
        <div className="Dropdown__dropdown">
          {buisnesList.map((buis: string) => (
            <div
              key={buis}
              className={classNames("Dropdown__dropdown-item", {
                "Dropdown__dropdown--active": buis === selectedOption
              })}
              onClick={() => {
                setSelectedOption(buis);
                setOpenDropdown(false);
              }}
            >
              {buis}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
