import axios from 'axios';

type createBody = {
  title: string
};

// SLIDES
export const createSlide = (token: string, body: createBody) => axios.post(`https://slides.googleapis.com/v1/presentations`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const customizeSlide = (token: string, id: string, body: any) => axios.post(`https://slides.googleapis.com/v1/presentations/${id}:batchUpdate`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
);

//  DOCS

export const createDoc = (token: string, body: createBody) => axios.post(`https://docs.googleapis.com/v1/documents`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
).then((res: any) => res.data);

export const customizeDoc = (token: string, id: string, body: any) => axios.post(`https://docs.googleapis.com/v1/documents/${id}:batchUpdate`,
  body,
  {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
);