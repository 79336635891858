import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie, setCookie } from "../../utils/CookieHelper";
import { AppDispatch, RootState } from "../../redux/store";
import {
  cleanPresentation,
  endLoading,
  getPresentationFromDoc,
  setObjAzure,
  startLoading,
} from "../../redux/slices/slidesSlice";
import { sendSlideToGoogle } from "../../utils/sender";
import { sendDoc } from "../../utils/sendDocs";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./DropdownExport.scss";
import { setExportError } from "../../redux/slices/scriptsSlice";

type Props = {
  presName: string;
  slideList?: any;
  presStyle: any;
  setExportModal: (arg: string) => void;
};

export default function DropdownExport({
  presName,
  slideList,
  presStyle,
  setExportModal,
}: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const azureToken = getCookie("azureRefreshToken");
  const token = getCookie("jwtToken");

  const { prompts } = useSelector((state: RootState) => state.slides);

  const exportGoogle = () => {
    console.log(slideList)
    setVisible(false);
    if (window.location.pathname === "/slide") {
      sendSlideToGoogle(presName || "go", slideList, "", presStyle);
      setExportModal("send");
    } else if (window.location.pathname === "/slidesArchive") {
      sendSlideToGoogle(presName, presStyle, "saveAndSend");
      setExportModal("send");
    } else if (window.location.pathname === "/scriptsArchive") {
      setExportModal("send");
      sendDoc(presName, "send", presStyle);
    } else {
      sendDoc(presName || "go", "send", presStyle);
      setExportModal("send");
    }
  };

  const exportDrive = async () => {
    setVisible(false);
    dispatch(startLoading());
    if (window.location.pathname === "/slide") {
      if (!azureToken) {
        setCookie('path', 'https://app.churchbrain.aislide', {'max-age': 12000});
        // document.cookie = "path=https://app.landdai.com/slide";
        window.location.href = "https://app.churchbrain.ai/api/auth/azure";
      } else {
        const res = await sendSlideToGoogle(
          presName || "go",
          slideList,
          "azure",
          presStyle,
        );
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          setExportModal("send");
        }
      }
    } else if (window.location.pathname === "/slidesArchive") {
      if (!azureToken) {
        dispatch(
          setObjAzure({
            presName,
            presStyle,
          }),
        );
        setCookie('path', 'https://app.churchbrain.ai/slidesArchive', {'max-age': 12000});
        // document.cookie = "path=https://app.landdai.com/slidesArchive";
        window.location.href = "https://app.churchbrain.ai/api/auth/azure";
      } else {
        const res = await sendSlideToGoogle(
          presName,
          presStyle,
          "saveAndSendAzure",
        );
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          setExportModal("send");
        }
      }
    } else if (window.location.pathname === "/scriptsArchive") {
      if (!azureToken) {
        dispatch(
          setObjAzure({
            presName,
            presStyle,
          }),
        );
        setCookie('path', 'https://app.churchbrain.ai/scriptsArchive', {'max-age': 12000});
        // document.cookie = "path=https://app.landdai.com/scriptsArchive";
        window.location.href = "https:/app.churchbrain.ai/api/auth/azure";
      } else {
        const res = await sendDoc(presName, "sendAzure", presStyle);
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          setExportModal("send");
        }
      }
    } else {
      if (!azureToken) {
        setCookie('path', 'https://app.churchbrain.ai/script', {'max-age': 12000});
        // document.cookie = "path=https://app.landdai.com/script";
        window.location.href = "https://app.churchbrain.ai/api/auth/azure";
      } else {
        const res = await sendDoc(presName || "go", "azure", presStyle);
        dispatch(endLoading());
        if (res) {
          dispatch(setExportError(res));
        } else {
          setExportModal("send");
        }
      }
    }
  };

  const exportPdf = async () => {
    setVisible(false);
    dispatch(startLoading());
    if (window.location.pathname === "/slide") {
      await sendSlideToGoogle(
        presName || "go",
        slideList,
        "azurePdf",
        presStyle,
      );
      dispatch(endLoading());
      setExportModal("send");
    } else if (window.location.pathname === "/slidesArchive") {
      await sendSlideToGoogle(presName, presStyle, "saveAndSendAzurePdf");
      dispatch(endLoading());
      setExportModal("send");
    } else if (window.location.pathname === "/scriptsArchive") {
      await sendDoc(presName, "Pdf", presStyle);
      dispatch(endLoading());
      setExportModal("send");
    } else {
      await sendDoc(presName || "go", "Pdf", presStyle);
      dispatch(endLoading());
      setExportModal("send");
    }
  };

  const exportPres = () => {
    setVisible(false);
    dispatch(cleanPresentation());
    if (token && presStyle) {
      const names = prompts
        ?.map((a: any) => {
          if (window.location.pathname === "/slide") {
            if (a?.second_title) {
              if (!a?.isScript) {
                return a?.second_title;
              }
            } else {
              return "";
            }
          } else {
            if (a?.second_title) {
              if (a?.isScript) {
                return a?.second_title;
              }
            } else {
              return "";
            }
          }
        })
        ?.filter((b: any) => b?.length > 0);

      const bodyText = {
        template_name: names[0] || "custom presentation",
        slides: [],
      } as any;

      let obj = {
        title: "",
        text: "",
      } as any;

      JSON.parse(presStyle?.style)?.blocks.forEach((item: any, i: number) => {
        if (item.type === "header-one") {
          bodyText.title = item.text.replaceAll("\n", "");
          if (
            JSON.parse(presStyle?.style)?.blocks[i + 1]?.type ===
              "header-two" ||
            JSON.parse(presStyle?.style)?.blocks[i + 1]?.type === "header-three"
          ) {
            bodyText.text = JSON.parse(presStyle?.style)?.blocks[
              i + 2
            ].text.replaceAll("\n", "");
          } else {
            bodyText.text = JSON.parse(presStyle?.style)?.blocks[
              i + 1
            ].text.replaceAll("\n", "");
          }
        } else if (item.type === "header-two" || item.type === "header-three") {
          if (obj?.title?.length && obj?.text?.length) {
            bodyText.slides.push(obj);
            obj = {
              title: "",
              text: "",
            };
          }
          obj.title = item.text.replaceAll("\n", "");
        } else {
          if (item.type === "unordered-list-item") {
            obj.text += " \t " + item.text.replaceAll("\n", "");
          } else {
            if (item?.text?.includes("Action Items:")) {
              obj.text += item.text.replaceAll("\n", "");
            }

            if (obj?.title?.includes("Conclusion")) {
              obj.text += item.text.replaceAll("\n", "");
            }
          }
        }

        if (i === JSON.parse(presStyle?.style)?.blocks.length - 1) {
          bodyText.slides.push(obj);
        }
      });

      dispatch(
        getPresentationFromDoc({
          token,
          body: bodyText,
        }),
      );

      navigate(`/slide`);
    }
  };

  return (
    <div className="DropdownExport">
      <button
        type="button"
        className={`DropdownExport__button ${
          (window.location.pathname === "/slidesArchive" ||
            window.location.pathname === "/scriptsArchive") &&
          "DropdownExport__button--archive"
        }`}
        onClick={() => setVisible(!visible)}
      >
        Export to
        {visible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </button>
      {visible && (
        <div className="DropdownExport__list">
          <button
            type="button"
            className="DropdownExport__item"
            onClick={exportGoogle}
          >
            {window.location.pathname === "/script" ||
            window.location.pathname === "/scriptsArchive"
              ? "Google Docs"
              : "Google Slides"}
          </button>
          <button
            type="button"
            className="DropdownExport__item"
            onClick={exportDrive}
          >
            One Drive
          </button>
          <button
            type="button"
            className="DropdownExport__item"
            onClick={exportPdf}
          >
            PDF
          </button>
        </div>
      )}
    </div>
  );
}
