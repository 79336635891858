import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';

import ColorPicker from '../ColorPicker/ColorPicker';
import Formatting from '../Formatting/Formatting';
import ImageUploader from '../ImageUploader/ImageUploader';

import award from '../../../../../images/award.svg';
import dice from '../../../../../images/dice.svg';
import paintbrush from '../../../../../images/paintbrush.svg';
import fileUp from '../../../../../images/file-up.svg';
import presBg from '../../../../../images/presBg.svg';
import moon from '../../../../../images/sun-moon.svg';
import Dropdown from '../Dropdown/Dropdown';

import './PresStyles.scss';

type Props = {
  itemObject: any,
  setItemObject: (arg: any) => void,
  prompt: any
}

export default function PresStyles({itemObject, setItemObject, prompt}: Props) {
  const [disableSave, setDisableSave] = useState(true);
  const [colorSave, setColorSave] = useState("#000000");
  const [styles, setStyles] = useState<any>({});

  useEffect(() => {
    setStyles({
      formatting: prompt?.format_options,
      imageStyle: prompt?.image_style,
      color: prompt?.color_palette,
      selectImage: prompt?.brand_logo,
      selectBgImage: prompt?.background_image,
    });
    if (prompt?.text_color) {
      setColorSave(prompt?.text_color)
    }
  }, [prompt]);

  useEffect(() => {
    let obj = {...itemObject, style: styles, save: disableSave, colorSave};
    if (styles?.selectBgImage) {
      obj.colorSave = colorSave;
    }
    console.log(styles);
    setItemObject(obj);
  }, [styles, disableSave, colorSave]);

  const changeColor = () => {
    if (!colorSave || colorSave === "#000000") {
      setColorSave('#ffffff')
    } else {
      setColorSave("#000000")
    }
  };

  return (
    <div className="PresStyles">
      <div className="PresStyles__togle-wrapper">
        <div className="PresStyles__togle">
          <div
            onClick={() => setDisableSave(!disableSave)}
            className={classNames("PresStyles__togle-select", {
              "PresStyles__togle-select--disable": !disableSave
            })}
          >
            <div className="PresStyles__togle-select-btn"></div>
          </div>
          <p>
            {`${prompt?.firstGeneration ? "Save" : "Update"} preset`}
          </p>
        </div>
      </div>
      <div className="PresStyles__style-item">
        <div className="PresStyles__item-img-wrap">
          <img src={dice} alt="dice" className="PresStyles__item-img"/>
        </div>
        <div className="PresStyles__item-text">
          <h2 className="PresStyles__item-title">
            Formatting Options
          </h2>
          <p className="PresStyles__item-info">
            You can choose one option
          </p>
        </div>
        <Formatting setStyles={setStyles} styles={styles} defaultValue={prompt?.format_options}/>
      </div>
      <div className="PresStyles__style-item">
        <div className="PresStyles__item-img-wrap">
          <img src={paintbrush} alt="paintbrush" className="PresStyles__item-img"/>
        </div>
        <div className="PresStyles__item-text">
          <h2 className="PresStyles__item-title">
            Color Palette
          </h2>
          <p className="PresStyles__item-info">
            You can choose one Color
          </p>
        </div>
        <ColorPicker setStyles={setStyles} styles={styles} defaultValue={prompt?.color_palette}/>
      </div>
      <div className="PresStyles__style-item">
        <div className="PresStyles__item-img-wrap">
          <img src={award} alt="award" className="PresStyles__item-img"/>
        </div>
        <div className="PresStyles__item-text">
          <h2 className="PresStyles__item-title">
            Image Style
          </h2>
          <p className="PresStyles__item-info">
            Choose the style of generated images you'd prefer
          </p>
        </div>
        <Dropdown setStyles={setStyles} styles={styles} defaultValue={prompt?.image_style}/>
      </div>
      <div className="PresStyles__style-item">
        <div className="PresStyles__item-img-wrap">
          <img src={fileUp} alt="fileUp" className="PresStyles__item-img"/>
        </div>
        <div className="PresStyles__item-text">
          <h2 className="PresStyles__item-title">
            Brand Logo (optional)
          </h2>
          <p 
            className="PresStyles__item-info"
            style={{
              width: '214px'
            }}
          >
            Upload your brand image as a JPG, JPEG or PNG file
          </p>
        </div>
        <ImageUploader
          type={"logo"}
          setStyles={setStyles}
          styles={styles}
          defaultValue={prompt?.brand_logo}
        />
      </div>
      <div className="PresStyles__style-item">
        <div className="PresStyles__item-img-wrap">
          <img src={presBg} alt="fileUp" className="PresStyles__item-img"/>
        </div>
        <div className="PresStyles__item-text">
          <h2 className="PresStyles__item-title">
            Background Image (optional)
          </h2>
          <p 
            className="PresStyles__item-info"
            style={{
              width: '214px'
            }}
          >
            Upload your background image as a JPG, JPEG or PNG file
          </p>
        </div>
        <ImageUploader 
          type={"background"}
          setStyles={setStyles} 
          styles={styles} 
          defaultValue={prompt?.background_image}
        />
      </div>
      {styles?.selectBgImage && (
        <div className="PresStyles__style-item">
          <div className="PresStyles__item-img-wrap">
            <img src={moon} alt="moon" className="PresStyles__item-img"/>
          </div>
          <div className="PresStyles__item-text">
            <h2 className="PresStyles__item-title">
              Optimize text contrast
            </h2>
            <p 
              className="PresStyles__item-info"
              style={{
                width: '330px'
              }}
            >
              Choose text color mode based on your background image: 
              dark mode for light images, light mode for dark images.
            </p>
          </div>
            <div
              onClick={changeColor}
              className={classNames("PresStyles__togle-selector PresStyles__togle-selector--black", {
                "PresStyles__togle-selector--disable-black": colorSave === '#ffffff'
              })}
            >
              <LightModeOutlinedIcon sx={{fontSize: 10, color: 'white'}}/>
              <div className="PresStyles__togle-selector-btn"
                style={{
                  right: colorSave === '#ffffff' ? '2px' : '16px',
                }}
              ></div>
              <DarkModeOutlinedIcon sx={{fontSize: 10, color: '#fff'}}/>
            </div>

            {/* {visibleTooltip && <div className="PresStyles__togle-tooltip">
              To continue styling, choose theme or choose customisation
              <img src={blueTail} alt="" className="PresStyles__tail"/>
            </div>} */}
          </div>
       )}
    </div>
  )
}
