import React, { ChangeEvent, useRef, useState } from 'react';
import './ChatBotRename.scss';

interface ChatBotRenameProps {
    initialValue: string;
    onSave: (value: string) => void;
    editing: boolean;
    setEditing: (value: boolean) => void;
  }
  
  const ChatBotRename: React.FC<ChatBotRenameProps> = ({ initialValue, editing, setEditing, onSave }) => {
  const [value, setValue] = useState(initialValue); 
  const ref = useRef<HTMLInputElement>(null);

  const handleSave = () => {
    onSave(value);
    setEditing(false);
  };

  const handleCancel = () => {
    setValue(initialValue);
    setEditing(false);
  };

  const handleClick = () => {
    //@ts-ignore
    setEditing(true);
    // ref?.current?.focus(); 
  };

  return (
    <div className="TitleInput">
      {editing ? (
        <input
          // ref={ref}
          className="TitleInput__input"
          type="text"
          value={value}
          autoFocus={true}
          onBlur={(e) => {
            handleSave()
            setEditing(false)}
          }
          onChange={(e) => setValue(e.target.value)}
        /> 
      ) : (
          <div
            onClick={() => {
              setEditing(true);
              handleClick()
            }}
          >
             {value}
            <div
              className="TitleInput__icon"
              onClick={() => {
                setEditing(true);
                handleClick()
              }}
            >
            </div>
          </div>
      )}
    </div>
  )


};

export default ChatBotRename;
