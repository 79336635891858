import React, { useState, useRef, useEffect } from 'react';
import { getCookie }  from '../../../../../utils/CookieHelper';
import { textToSpeach } from '../../../../../api/trainerBotApi';
import './MessageComponent.scss';
import speaker from '../../../../../images/volume.svg';
import closeIcon from '../../../../../images/stop-music.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { startLoading, endLoading } from '../../../../../redux/slices/trainerBotSlice';

interface Message {
  id: string;
  content: string;
  voiceLink: string;
  role: 'system' | 'assistant' | 'user';
  created_at: string;
}

interface MessageComponentProps {
  message: Message;
  currentlyPlayingId: string | null;
  onAudioPlay: (messageId: string) => void;
  onAudioPause: () => void;
}

const MessageComponent: React.FC<MessageComponentProps> = ({
  message,
  currentlyPlayingId,
  onAudioPlay,
  onAudioPause,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const dispatch = useDispatch<any>();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const token = getCookie('jwtToken');
  const lines = message?.content?.split('\n');

  const playAudio = async () => {
    dispatch(startLoading());
     let link = await textToSpeach({text: message.content, message_id: message.id}, token);
      dispatch(endLoading());
    if (!isPlaying) {
      pauseCurrentAudio(); // Pause any currently playing audio
      onAudioPlay(message.id);
      const audio = new Audio(link);
      audio.addEventListener('ended', handleAudioEnded); // Add event listener for 'ended' event
      audio.play().catch(error => {
        setIsPlaying(false);
        onAudioPause();
      });
      setIsPlaying(true);
      audioRef.current = audio;
    } else {
      pauseCurrentAudio();
      if (message.id !== currentlyPlayingId) {
        onAudioPlay(message.id);
        const audio = new Audio(link);
        audio.addEventListener('ended', handleAudioEnded); // Add event listener for 'ended' event
        audio.play().catch(error => {
          setIsPlaying(false);
          onAudioPause();
        });
        setIsPlaying(true);
        audioRef.current = audio;
      }
    }
  };
  

  const handleAudioEnded = () => {
    setIsPlaying(false);
    onAudioPause();
  };

  const pauseCurrentAudio = () => {
    if (audioRef.current && isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
      onAudioPause();
    }
  };

 

  useEffect(() => {
    if (message.id !== currentlyPlayingId) {
      pauseCurrentAudio();
    }
  }, [currentlyPlayingId]);

  return (
    <div className={`MessageComponent  MessageComponent__${message.role}`} key={message.id}>
      <div className="MessageComponent__wrapper">
      {
        message.role !== 'system' && (
          <>
            {message.role === 'assistant' ? (
              <div className="MessageComponent__icon__assistant"></div>
            ) : (
              <div className="MessageComponent__icon"></div>
            )}
          </>
        )
      }

      {
        message.role === 'system' && (
          <>
            <div className="MessageComponent__icon__system"></div>
          </>
         
        )
      }
{/* //TitleInput */}

        <div className={`MessageComponent__text ${message.role === 'system' && 'warning'}`}>
          {lines?.map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
        {message.role === 'assistant' ? (
          <div
            className="MessageComponent__listen"
            onClick={() =>
              playAudio()
            }
          >
            <img src={isPlaying ? closeIcon : speaker} alt="" />
          </div>
        ) : (
          <div className="MessageComponent__placeholder"></div>
        )}
      </div>
    </div>
  );
};

export default MessageComponent;
