import axios from "axios";

export const sendOneDrive = (token: string, body: any) =>
  axios.post(
    `https://app.churchbrain.ai/api/presentation/export-onedrive`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  );

export const exportPdf = (token: string, body: any) =>
  fetch(
    `https://app.churchbrain.ai/api/presentation/export-pdf-from-onedrive`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    },
  );
