import { Outlet, Navigate } from 'react-router-dom';
import { getCookie, setCookie } from './CookieHelper';

const PrivateRoutes = () => {

  if(~window.location.href.indexOf('payment=special')){
    setCookie('planLimited', 1, {});
  }

  const token = getCookie('jwtToken');

  return (
    token ? <Outlet /> : <Navigate to="/login"/>
  )
};

export default PrivateRoutes;
