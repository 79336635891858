import React, { useEffect, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import TextAreaChat from "../../../TextAreaChat/TextAreaChat";
import "./ChatContainer.scss";
import pencil from "../../../../images/PencilSimple.svg";
import MessageComponent from "./MessageComponent/MessageComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import Loader from "../../../Loader/Loader";

import { getCookie } from "../../../../utils/CookieHelper";
import {
  addMessage,
  renameChat,
  startLoading,
  endLoading,
  startLoadingPage,
  endLoadingPage,
} from "../../../../redux/slices/trainerBotSlice";
import ChatBotRename from "../../../Modals/ChatBotRename/ChatBotRename";
import { useSocket } from "../../../../pages/Home/Home";

interface ChatData {
  messages: Message[] | [];
}

interface Message {
  id: string;
  content: string;
  voiceLink: string;
  role: "system" | "assistant" | "user";
  created_at: string;
}

interface chatRequestMessage {
  chat_id: number;
  message: string;
  email: string;
}
const api_url = "https://app.churchbrain.ai";
// const api_url = 'http://localhost:8000';

export default function ChatContainer() {
  const { chat_id, chatHistory, chats, chatTitle, loadingPage } = useSelector(
    (state: RootState) => state.trainerBot,
  );
  const { user } = useSelector((state: RootState) => state.scripts);
  const [socket, setSocket] = React.useState<Socket | null>(null);
  const newSocket = useSocket();

  // const chat = chats.find((chat) => chat.id === chat_id);
  const [title, setTitle] = useState<string>(chatTitle || "Training Chat");

  const [editTitle, setEditTitle] = useState<boolean>(false);

  const messageBlockRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch<any>();
  const token = getCookie("jwtToken");

  const [currentlyPlayingId, setCurrentlyPlayingId] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (newSocket) {
      newSocket.on("assistant-response", (data: any) => {
        dispatch(
          addMessage({
            id: data.message.id,
            content: data.message.content,
            voiceLink: "",
            role: "assistant",
            created_at: new Date().toISOString(),
          }),
        );
        dispatch(endLoading());
      });

      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }
  }, [newSocket]);

  useEffect(() => {
    if (messageBlockRef.current) {
      messageBlockRef.current.scrollTo({
        top: messageBlockRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    setTitle(chatTitle || "Training Chat");
  }, [chatHistory, chat_id]);

  useEffect(() => {
    if (!chat_id) {
      dispatch(startLoadingPage());
    } else {
      dispatch(endLoadingPage());
    }
  }, [chat_id]);

  const playAudio = (messageId: string) => {
    setCurrentlyPlayingId(messageId);
  };

  const pauseAudio = () => {
    setCurrentlyPlayingId(null);
  };

  const sendMessage = (text: string) => {
    socket &&
      socket.emit("create-chat-message", {
        chat_id: chat_id,
        message: text,
        email: user.email,
      });
    dispatch(startLoading());
    dispatch(
      addMessage({
        id: chatHistory.length + 2,
        content: text,
        voiceLink: "",
        role: "user",
        created_at: new Date().toISOString(),
      }),
    );
  };

  const handleSaveTitle = (value: string) => {
    setTitle(value);
    dispatch(
      renameChat({
        email: user.email,
        chat_id: chat_id,
        title: value,
        token: token,
      }),
    );
  };

  return (
    <div className="ChatContainer">
      <div className="ChatContainer__wrapper">
        <div className="ChatContainer__message-wrapper">
          {!chatHistory.length || !chat_id ? (
            <div className="ChatContainer__Empty">
              <div className="ChatContainer__Empty__header">
                <Loader />
              </div>
            </div>
          ) : (
            <>
              <div className="ChatContainer__message-block__header">
                {editTitle ? (
                  <ChatBotRename
                    initialValue={title}
                    editing={editTitle}
                    setEditing={setEditTitle}
                    onSave={handleSaveTitle}
                  />
                ) : (
                  <>
                    <div
                      className="ChatContainer__message-block__header__title"
                      onClick={() => setEditTitle(true)}
                    >
                      {title}
                    </div>
                    <div
                      className="ChatContainer__message-block__header__edit"
                      onClick={() => setEditTitle(true)}
                    >
                      <img src={pencil} alt="edit" />
                    </div>
                  </>
                )}
              </div>
              <div
                className="ChatContainer__message-block__body"
                ref={messageBlockRef}
              >
                {chatHistory.map((message, index) => (
                  <div key={message.id}>
                    <MessageComponent
                      message={message}
                      currentlyPlayingId={currentlyPlayingId}
                      onAudioPlay={playAudio}
                      onAudioPause={pauseAudio}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        {!chatHistory.length || !chat_id ? (
          <></>
        ) : (
          <TextAreaChat onSubmit={(val) => sendMessage(val)} />
        )}
      </div>
    </div>
  );
}
