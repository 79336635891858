import React from 'react';
import TextSlider from '../TextSlider/TextSlider';
import './Loader.scss';

type Props = {
  page?: boolean
};

export default function Loader({ page }: Props) {
  return (
    <div className="Loader">
      <h2 className="Loader__title">{page ? 'Creating' : 'Loading'}</h2>
      <p className="Loader__text"
        style={{
          marginBottom: page ? '0' : '64px',
        }}
      >
        {page ? (
          "Please DO NOT REFRESH PAGE as it might take a minute or two for your custom content to be created."
        ) : (
          "Please wait"
        )}
      </p>
      {page && (
        <div id="ProgressBar">
          <div id="Progress"></div>
        </div>
      )}
      {page ? <TextSlider /> : <div className="loader circle-loader-1"></div>}
    </div>
  )
}
