import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import backIcon from '../../../../images/arrow-left.svg';
import trash from '../../../../images/trash.svg';
import edit from '../../../../images/edit.svg';
import rightArrow from '../../../../images/chevron-right.svg';
import leftArrowDisable from '../../../../images/chevron-left.svg';
import Loader from '../../../Loader/Loader';
import './SlideArchive.scss';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { addPresentation, changeSidebarStatus, endLoading, getPresentations, removePresentation, setObjAzure, startLoading, updatePres } from '../../../../redux/slices/slidesSlice';
import { getCookie } from '../../../../utils/CookieHelper'
import ExportModal from '../../../Modals/ExportModal/ExportModal';
import { sendSlideToGoogle } from '../../../../utils/sender';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DropdownExport from '../../../DropdownExport/DropdownExport';
import { setExportError } from '../../../../redux/slices/scriptsSlice';

export default function SlideArchive() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [hovered, setHovered] = useState('');
  const [openInput, setOpenInput] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [exportModal, setExportModal] = useState("");
  const [page, setPage] = useState(0);
  
  const token = getCookie('jwtToken');
  const rediePath = getCookie('path');

  const {
    presentationsList,
    listLength,
    loading,
    objAzure
  } = useSelector((state: RootState) => state.slides);

  useEffect(() => {
    const func = async() => {
      const res = await sendSlideToGoogle(objAzure?.presName || 'go', objAzure?.presStyle, "saveAndSendAzure");
      dispatch(setObjAzure({}));
      dispatch(endLoading());
      if (res) {
        dispatch(setExportError(res));
      } else {
        setExportModal('send');
      }
      document.cookie = "path=";
    }
    if (rediePath) {
      func();
    }
    dispatch(changeSidebarStatus(false));
  }, []);

  useEffect(() => {
    token && dispatch(getPresentations({
      body: {
        take: '10',
        skip: `${page * 10}`
      },
      token
    }));
  }, [token, page]);

  useEffect(() => {
    token && dispatch(getPresentations({
      body: {
        take: '10',
        skip: '0'
      },
      token
    }));
  }, [presentationsList?.length === 0]);

  const remove = (id: number) => {
    if (token) {
      const obj = {
        body: {
          presentationId: id
        },
        token
      }
      dispatch(removePresentation(obj));
    }
  };

  const updateNamePres = (id: number, text: string) => {
    setOpenInput('');
    const obj = {
      title: inputValue || text,
      presentation_id: `${id}`
    }

    dispatch(addPresentation({
      body: obj,
      token
    }));

    dispatch(updatePres({
      id,
      title: inputValue || text
    }));
  };

  return (
    <div className="SlideArchive">
      <div
        className="SlideArchive__header"
      >
        <img
          className="SlideArchive__back-btn"
          src={backIcon}
          alt="back"
          onClick={() => navigate('/slides')}
        />
        <p className="SlideArchive__back-text">My Saved Presentations </p>
      </div>
      {loading ? <Loader /> : (
        <div className="SlideArchive__table">
          <div className="SlideArchive__table-header">
            <p className="SlideArchive__pres-name">
              Name of Presentation
            </p>
            <p className="SlideArchive__temp-name">
              Template Name
            </p>
            <p className="SlideArchive__data">
              Date Saved
            </p>
          </div>
          {presentationsList?.length > 0 ? (
            <div className="SlideArchive__table-main">
              {presentationsList?.map((el: any) => (
                <div
                  key={el?.id}
                  className="SlideArchive__table-row"
                  onMouseEnter={() => setHovered(el?.id)}
                >
                  <div className="SlideArchive__pres-name">
                    {hovered === el?.id && (
                      <div
                        className="SlideArchive__hover-edit"
                        onClick={() => setOpenInput(el?.id)}
                      >
                        <img src={edit} alt="edit" />
                      </div>
                    )}
                    {openInput === el?.id ? (
                      <input
                        className="SlideArchive__edit-input"
                        type="text"
                        value={inputValue}
                        autoFocus={true}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value)}
                        onBlur={() => updateNamePres(el?.id, el?.title)}
                      />
                    ) : el?.title}
                  </div>
                  <p className="SlideArchive__temp-name">
                    {el?.template_name}
                  </p>
                  <p className="SlideArchive__data">
                    {el?.created_at ? new Date(el?.created_at).toISOString().split('T')[0] : ''}
                  </p>
                  {hovered === el?.id && (
                    <div className="SlideArchive__hover-btns">
                      <div
                        className="SlideArchive__hover-btn"
                        onClick={() => navigate(`/slide?edit=${el?.id}`)}
                      >
                        <ModeEditIcon />
                        <span className="tooltiptext">Edit</span>
                      </div>
                      <div
                        className="SlideArchive__hover-btn"
                        onClick={() => remove(el?.id)}
                      >
                        <img src={trash} alt="delete" />
                        <span className="tooltiptext">Delete</span>
                      </div>
                      <DropdownExport 
                        presName={el?.title}
                        presStyle={(el?.text !== 'text' && el?.text) ? JSON.parse(el?.text) : ""}
                        setExportModal={setExportModal}                         
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="SlideArchive__empty">
              <p className="SlideArchive__empty-text">You have no presenations</p>
            </div>
          )}
          
          {listLength > 10 && (
            <div className="SlideArchive__pagination">
              <div className="SlideArchive__pagination-text">
                {page * 10}-{page * 10 + presentationsList?.length} of {listLength}
              </div>
              <div className="SlideArchive__pagination-btns">
                <div
                  onClick={() => page > 0 && setPage(page - 1)}
                >
                  <img
                    src={page > 0 ? rightArrow : leftArrowDisable}
                    style={{
                      transform: page > 0 ? 'rotate(180deg)' : 'rotate(0)'
                    }}
                    alt="prev"
                  />
                </div>
                <div
                  onClick={() => (((page + 1) * 10) < (Math.ceil(listLength / 10) * 10)) && setPage(page + 1)}
                >
                  <img
                    src={(((page + 1) * 10) < (Math.ceil(listLength / 10) * 10)) ? rightArrow : leftArrowDisable}
                    style={{
                      transform: (((page + 1) * 10) < (Math.ceil(listLength / 10) * 10)) ?  'rotate(0)' : 'rotate(180deg)'
                    }}
                    alt="next"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {exportModal?.length > 0 && <ExportModal text={exportModal} close={() => setExportModal("")} />}
    </div>
  )
}
