import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import './Formatting.scss';

type Props = {
  setStyles: (arg: any) => void,
  styles: any,
  defaultValue: "Bold" | "Modern" | "Minimalistic",
}

export default function Formatting({ setStyles, styles, defaultValue }: Props) {
  const [formatting, setFormatting] = useState<"Bold" | "Modern" | "Minimalistic">(defaultValue || "Bold");

  useEffect(() => {
    if (styles.selectBgImage) {
      setFormatting("Minimalistic");
    }
  }, [styles.selectBgImage]);

  useEffect(() => {
    if (formatting === "Minimalistic") {
      setStyles({...styles, formatting});
    } else {
      setStyles({...styles, formatting, selectBgImage: ""});
    }
  }, [formatting]);

  return (
    <div className="Formatting">
      <button
        type="button"
        className={classNames("Formatting__item-formatting-btn", {
          "Formatting__item-formatting-btn--active": formatting === 'Bold'
        })}
        onClick={() => setFormatting('Bold')}
      >
        Bold
      </button>
      <button
        type="button"
        className={classNames("Formatting__item-formatting-btn", {
          "Formatting__item-formatting-btn--active": formatting === 'Modern'
        })}
        onClick={() => setFormatting('Modern')}
      >
        Modern
      </button>
      <button
        type="button"
        className={classNames("Formatting__item-formatting-btn", {
          "Formatting__item-formatting-btn--active": formatting === 'Minimalistic'
        })}
        onClick={() => setFormatting('Minimalistic')}
      >
        Minimalistic
      </button>
    </div>
  )
}
