import React from 'react';
import './SaveButton.scss'

type Props = {
  action: (arg: any) => void,
  text: string
}

export default function SaveButton({action, text}: Props) {
  return (
    <div
      className="SaveButton"
      onClick={action}
    >
      {text}
    </div>
  )
}
