export const plansLimited = [
  {
    title: "Free Trial",
    sub: "5 Presentations",
    priceText: "$0 for new users",
    priceYear: "$0 for new users",
    texts: [
      "Test Drive All Site Features",
      "Ai Generated Presentations",
      "Up To 5,000 Words",
      "Intuitive & User Friendly",
      "Create Presentations 10x Faster",
      "Great Customer Service",
      "100% Secure"
    ],
    btnText: "GET STARTED FREE",
    quantity: 1,
    priceId: 'price_1NfkPOGsLlGiOrc8ESV3aAuq',
    priceIdYearly: 'price_1NfkPOGsLlGiOrc8ESV3aAuq',
  },
  {
    title: "Ministry Plan",
    sub: "100 presentations",
    priceYear: "$59/ month",
    priceText: "$79 / month",
    texts: [
      "Everything in the Individual plan Package Plus+",
      "50 Landai Generated Presentations a Month",
      "Unlimited Number of Words & Characters",
      "Unlimited Number of Ai Generated Photos",
      "Priority Beta Access to New Features",
      "LandDAiBot: On-Demand CorporateTrainerBot (coming soon)",
      "Employee Analytics App (coming soon)"
    ],
    btnText: "MINISTRY PLAN",
    quantity: 1,
    priceId: 'price_1NnIxsJ4EYns10HuZC4o7Xwi',
    priceIdYearly: 'price_1NnIxsJ4EYns10HuM72qryQR',
  },
  {
    title: "Individual Plan",
    sub: "10 presentations a month",
    priceText: "$39 / month",
    priceYear: "$29 / month",
    texts: [
      "10 Custom Generated Presentations ",
      "Dozens of Ai Templates for HR and L&D Functions",
      "Landai Editor (Google Docs like editor)",
      "Ai Content Writer",
      "Ai Training Presentation Generator",
      "Ai Slide Deck Generator",
      "1-Click GoogleSuite Export"
    ],
    btnText: "INDIVIDUAL PLAN",
    quantity: 1,
    priceId: 'price_1NnIweJ4EYns10HuM8atB1De',
    priceIdYearly: 'price_1NnIweJ4EYns10HuBbJAfuyS',
  }
]

export const plans = [
  {
    title: "Free Trial",
    sub: "5 Presentations",
    priceText: "$0 for new users",
    priceYear: "$0 for new users",
    texts: [
      "Test Drive All Site Features",
      "Ai Generated Presentations",
      "Up To 5,000 Words",
      "Intuitive & User Friendly",
      "Create Presentations 10x Faster",
      "Great Customer Service",
      "100% Secure"
    ],
    btnText: "GET STARTED FREE",
    quantity: 1,
    priceId: 'price_1NfkPOGsLlGiOrc8ESV3aAuq',
    priceIdYearly: 'price_1NfkPOGsLlGiOrc8ESV3aAuq',
  },
  {
    title: "Ministry Plan",
    sub: "100 presentations",
    priceText: "$129 / month",
    priceYear: "$99 / month",
    texts: [
      "Everything in the Individual plan Package Plus+",
      "50 Landai Generated Presentations a Month",
      "Unlimited Number of Words & Characters",
      "Unlimited Number of Ai Generated Photos",
      "Priority Beta Access to New Features",
      "LandDAiBot: On-Demand CorporateTrainerBot (coming soon)",
      "Employee Analytics App (coming soon)"
    ],
    btnText: "MINISTRY PLAN",
    quantity: 1,
    priceId: 'price_1NnIxrJ4EYns10HuAQ3w4pzJ',
    priceIdYearly: 'price_1NnIxsJ4EYns10HuG5aaeoTK',
  },
  {
    title: "Individual Plan",
    sub: "10 presentations a month",
    priceText: "$79 / month",
    priceYear: "$59 / month",
    texts: [
      "10 Custom Generated Presentations ",
      "Dozens of Ai Templates for HR and L&D Functions",
      "Landai Editor (Google Docs like editor)",
      "Ai Content Writer",
      "Ai Training Presentation Generator",
      "Ai Slide Deck Generator",
      "1-Click GoogleSuite Export"
    ],
    btnText: "INDIVIDUAL PLAN",
    quantity: 1,
    priceId: 'price_1NnIweJ4EYns10HurryNxQKr',
    priceIdYearly: 'price_1NnIweJ4EYns10HunB4zeIdD',
  }
]

