import ChatList from './ChatList/ChatList';
import ChatContainer from './ChatContainer/ChatContainer';
import './ChatBotSection.scss';
 

export default function ChatBotSection() {
  // const {loading} = useSelector((state: RootState) => state.trainerBot);  


  return (
    <>
      <div className="ChatBotSection">
        <ChatContainer/>
        <ChatList />
      </div>
    </>
  )
}
