import React, { createContext, useContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import { io, Socket } from 'socket.io-client';
import { Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AccountSection from '../../components/Sections/AccountSection/AccountSection';
import DocArchive from '../../components/Sections/DocsSection/DocArchive/DocArchive';
import DocSection from '../../components/Sections/DocsSection/DocSection/DocSection';
import DocsSection from '../../components/Sections/DocsSection/DocsSection';
import EmptySection from '../../components/Sections/EmptySection/EmptySection';
import SlideArchive from '../../components/Sections/SlidesSection/SlideArcive/SlideArchive';
import SlideSection from '../../components/Sections/SlidesSection/SlideSection/SlideSection';
import SlidesSection from '../../components/Sections/SlidesSection/SlidesSection';
import ChatBotSection from '../../components/Sections/TrainerBotSection/ChatBotSection';
import SideBar from '../../components/SideBar/SideBar';
import { AppDispatch, RootState } from '../../redux/store';
import { setExportError } from '../../redux/slices/scriptsSlice';
import './Home.scss';
import { getCookie } from '../../utils/CookieHelper';

const SocketContext = createContext<Socket | null>(null);

export const useSocket = () => useContext(SocketContext);

const api_url = 'https://app.churchbrain.ai';

export default function Home() {
  const dispatch = useDispatch<AppDispatch>();
  const token = getCookie('jwtToken');

  const [newSocket, setNewSocket] = useState<Socket | null>(null);


  const { exportError, user } = useSelector((state: RootState) => state.scripts);

  useEffect(() => {
    const socket: Socket = io(api_url, {
      transports: ['websocket'],
      query: {
        id: user.id,
        token: token,
      },
    });

    if (socket) {
      socket.on('connect', () => {
        console.log('good')
        setNewSocket(socket);
      })
    }
  }, [token])

  useEffect(() => {
    if (exportError) {
      setTimeout(() => {
        dispatch(setExportError(''));
      }, 4000);
    }
  }, [exportError])

  return (
    <div className="Home">
      {exportError && <div className="Home__alert">
        <Alert severity="error">{exportError}</Alert>
      </div>}
      <SideBar />
      <SocketContext.Provider value={newSocket}>
        <main className="Home__main">
          <Routes>
            <Route path="*" element={<Navigate to="/slides" />} />
            <Route path="chat" element={<ChatBotSection />} />
            <Route path="analytics" element={<EmptySection />} />
            <Route path="learn" element={<EmptySection />} />
            <Route path="settings" element={<AccountSection />} />
            <Route path="script" element={<DocSection />} />
            <Route path="scripts" element={<DocsSection />} />
            <Route path="slides" element={<SlidesSection />} />
            <Route path="slide" element={<SlideSection />} />
            <Route path="slidesArchive" element={<SlideArchive />} />
            <Route path="scriptsArchive" element={<DocArchive />} />
          </Routes>
        </main>
      </SocketContext.Provider>
    </div>
  )
}
