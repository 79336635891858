import React, { ChangeEvent, useEffect, useState } from 'react';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { deleteBg, deleteLogo, uploadImg } from '../../../../../api/presentationApi';
import { getCookie } from '../../../../../utils/CookieHelper';
import './ImageUploader.scss';
import classNames from 'classnames';

type Props = {
  type: 'logo' | 'background',
  setStyles: (arg: any) => void,
  styles: any,
  defaultValue: string
}

export default function ImageUploader({ setStyles, styles, defaultValue, type }: Props) {
  const [selectedImage, setSelectedImage] = useState<any>(defaultValue || "");
  const [selectedBgImage, setSelectedBgImage] = useState<string>(defaultValue || "");
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const token = getCookie('jwtToken');

  useEffect(() => {
    if (type !== 'logo' && styles.formatting && styles.formatting !== "Minimalistic") {
      setSelectedBgImage("");
    }
  }, [styles.formatting]);

  useEffect(() => {
    if (type === 'logo') {
      setStyles({...styles, selectImage: selectedImage})
    } else {
      setStyles({...styles, selectBgImage: selectedBgImage, formatting: "Minimalistic"})
    }
  }, [selectedImage, selectedBgImage]);

  const handleImageChange = async(event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      if (token) {
        const res = await uploadImg(formData, token);
        if (type === 'background') {
          setSelectedBgImage(res)
        } else {
          setSelectedImage(res)
        }
      }
    }
    event.target.files = null;
  };

  const deleteImage = async(e: any) => {
    e.preventDefault();
    if (token) {
      if (type === 'logo') {
        setSelectedImage("")
        await deleteLogo(token);
      } else {
        setSelectedBgImage("")
        await deleteBg(token);
      }
    }

  };

  return (
    <label 
      htmlFor={`file-input-${type}`} 
      className={classNames("ImageUploader", {
        "ImageUploader--uploaded": (type === 'logo' && selectedImage) || (type === 'background' && selectedBgImage)
      })}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
    >
      {(type === 'logo' && !selectedImage) || (type === 'background' && !selectedBgImage) ? 'Upload your Image' : (
        <>
          <CheckCircleOutlinedIcon sx={{ width: 16, height: 16, color: '#1A9817'}}/>
          Uploaded
          {showDelete && <div
            className="ImageUploader__delete-icon"
            onClick={deleteImage}
          >
            <CloseIcon
              sx={{
                fontSize: 10,
                fontWeight: 'bold',
                color: '#5A6861'
              }}
            />
          </div>}
        </>
      )}
      <input
        className="ImageUploader__file-input"
        id={`file-input-${type}`}
        accept="image/jpeg, image/jpg, image/png"
        type="file" 
        onChange={(e: any) => {
          handleImageChange(e)
          e.target.value = "";
        }}
      />
      {(type !== 'logo' && showDelete) && (
        <div className="ImageUploader__tooltip">
          Optimal image size is 16:9(1920x1080)
        </div>
      )}
    </label>
  )
}
