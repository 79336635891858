import React from 'react'
import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton';
import CloseIcon from '@mui/icons-material/Close';
import './CloseModal.scss';

type Props = {
  action: any,
  text: string,
  closeNoSave: any,
  close: any
};

export default function CloseModal({
  action,
  text,
  closeNoSave,
  close }: Props) {

  return (
    <div className="CloseModal">
      <div className="CloseModal__wrapper">
        <div
          className="CloseModal__close"
          onClick={close}
        >
          <CloseIcon
            sx={{
              color: '#5A6861'
            }}
          />
        </div>
        <p>{text}</p>
        <div className="CloseModal__btns">
          <ConfirmButton
            text={"Save"}
            action={action}
          />
          <div
            className="CloseModal__btn"
            onClick={closeNoSave} 
          >
            No
          </div>
        </div>
      </div>
    </div>
  )
}
