import React, { ChangeEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateScript } from '../../../../../redux/slices/scriptsSlice';
import { updateName } from '../../../../../redux/slices/slidesSlice';
import { AppDispatch, RootState } from '../../../../../redux/store';
import './TitleInput.scss';

export default function TitleInput() {
  const dispatch = useDispatch<AppDispatch>();
  const ref = useRef(null);
  const [openInput, setOpenInput] = useState(false);

  const { script } = useSelector((state: RootState) => state.scripts);
  const { presName } = useSelector((state: RootState) => state.slides);

  const handleClick = () => {
    //@ts-ignore
    ref?.current?.focus();
  };

  return (
    <div className="TitlePageInput">
      {openInput ? (
        <input
          ref={ref}
          className="TitlePageInput__input"
          type="text"
          value={window.location.pathname === '/slide' ? presName : script?.title}
          autoFocus={true}
          onBlur={() => setOpenInput(false)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            window.location.pathname === '/script' ? dispatch(dispatch(updateScript({
              ...script,
              title: e.currentTarget.value
            }))) : dispatch(updateName(e.currentTarget.value));
          }}
        /> 
      ) : (
          <div
            onClick={() => {
              setOpenInput(true);
              handleClick()
            }}
          >
            {(presName?.length > 25 || script?.title?.length > 25)
              ? window.location.pathname === '/slide' ? presName?.slice(0, 25) + '...' : script?.title?.slice(0, 25) + '...'
              : window.location.pathname === '/slide' ? presName : script?.title}
            <div
              className="TitlePageInput__icon"
              onClick={() => {
                setOpenInput(true);
                handleClick()
              }}
            >
            </div>
          </div>
      )}
    </div>
  )
}
