import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { AppDispatch, RootState } from '../../../redux/store';
import { 
  sendScriptToAI, 
  setError, 
  setTextareaDocText
} from '../../../redux/slices/scriptsSlice';

import { decreaseCredit } from '../../../api/scriptsApi';
import LowBalanseModal from '../LowBalanseModal/LowBalanseModal';
import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton';
import PromptSlider from '../../PromptSlider/PromptSlider';

import { getCookie } from '../../../utils/CookieHelper';
import { useDispatch, useSelector } from 'react-redux';
import PresentationGenModal from '../PresentationGenModal/PresentationGenModal';
import './AiGenerateModal.scss';

type Props = {
  setModal: (arg: boolean) => any
}

export default function AiGenerateModal({ setModal }: Props) {
  // const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  // const [finalText, setFinalText] = useState('');
  // const [secondTitle, setSecondTitle] = useState('');
  const token = getCookie('jwtToken');

  const { user } = useSelector((state: RootState) => state.scripts);
  const { prompts } = useSelector((state: RootState) => state.slides);

  // const sendAi = async () => {
  //   const id = prompts.filter((prompt: any) => {
  //     if (prompt.isScript) {
  //       return prompt?.prompt?.id
  //     }
  //   });

  //   const templateName = prompts?.map((a: any) => {
  //     if (a?.second_title) {
  //       return a?.second_title;
  //     }
  //   })[0];

  //   const capitalizeTitleName = secondTitle?.split(' ').map((item: string) => item?.charAt(0)?.toUpperCase() + item.slice(1)).join(' ');

  //   const body = {
  //     title_second: capitalizeTitleName,
  //     title: templateName ? templateName : 'name',
  //     prompt: "",
  //     isCustom: finalText,
  //   } as any;

  //   if (id[0]?.prompt?.id && capitalizeTitleName) {
  //     body.promptId = id[0]?.prompt?.id
  //   }

  //   dispatch(sendScriptToAI({
  //     token,
  //     body,
  //   }));

  //   token && await decreaseCredit(token);

  //   setModal(false);
  // };

  return (
    <>
      {openSubmitModal ? (
        <LowBalanseModal />
      ) : (
        <div className="AiGenerateModal">
          <PresentationGenModal
            setOpenSubmitModal={setOpenSubmitModal}
            user={user}
          />
        </div>
      )}
    </>
  )
}
