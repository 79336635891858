import React, { useState, useEffect } from 'react';
import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { editorToolbar } from './EditorSetting/EditorSettings';
import './TextEditor.scss';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { changeSlideText } from '../../redux/slices/slidesSlice';
import MaxLengthModal from '../Modals/MaxLengthModal/MaxLengthModal';

type Props = {
  selectSlide: any
  setSelectSlide: (arg: any) => any,
  type: 'title' | 'text',
  isImage: boolean
};

export default function TextEditor({ selectSlide, type, setSelectSlide, isImage }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const [maxLength, setMaxLength] = useState("");
  const [editorState, setEditorState] = useState<EditorState>(
    () => EditorState.createEmpty()
  );

  const createState = (t: string) => {
    return EditorState.createWithContent(ContentState.createFromText(t));
  };

  useEffect(() => {
    if (type === "title") {
      if (selectSlide?.titleStyle) {
        setEditorState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(selectSlide?.titleStyle))));
      } else {
        setEditorState(createState(selectSlide?.title));
      }
    } else {
      if (selectSlide?.textStyle) {
        setEditorState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(selectSlide?.textStyle))));
      } else {
        setEditorState(createState(selectSlide?.text));
      }
    };

    setTimeout(bulletpointColorize, 5);
  }, [selectSlide]);

  const handleUpdate = () => {
    if (type === "title") {
      const titleObj = {
        ...selectSlide,
        titleStyle: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      }
      dispatch(changeSlideText(titleObj));
    } else {
      const textObj = {
        ...selectSlide,
        textStyle: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      }
      dispatch(changeSlideText(textObj));
    }
  };

  const handleChange = (e: any) => {
    setTimeout(bulletpointColorize, 5);
    setEditorState(e);
  };

  const bulletpointColorize = () => {
    const listItems = window.document.querySelectorAll('li');
  
    if (listItems ) {

      listItems.forEach((item) => {
        const dataOffset = item.getAttribute('data-offset-key');
        const spanElement = document.querySelector(`li span[data-offset-key="${dataOffset}"]`) as HTMLElement;
        if (spanElement) {
          item.style.color = spanElement.style.color;
        }
      });
  
    }
  }

  return (
    <div
      style={{ width: '100%'}}
    >
      <Editor
        customBlockRenderFunc={bulletpointColorize}
        editorState={editorState}
        onEditorStateChange={handleChange}
        wrapperClassName={`wrapper-class ${!isImage && "wrapper-class--center"}`}
        editorClassName={`editor-class editor-class--${type} ${selectSlide?.position === 0 && "editor-class--hyper-title"}`}
        toolbarClassName={`toolbar-class ${!isImage && "toolbar-class--center"}`}
        toolbar={editorToolbar}
        toolbarOnFocus
        onBlur={handleUpdate}
      />
      {maxLength.length > 0 && (
        <MaxLengthModal text={maxLength} close={() => {
          setMaxLength('');
        }} />
      )}
    </div>
  )
}

