import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { updateOpenAIText } from '../../api/presentationApi';
import {
  createScript,
  decreaseCredit,
  deleteScript,
  getScript,
  getScriptsList,
  getUser,
  regenScript,
  resaveScript,
} from "../../api/scriptsApi";
import { changeSidebarStatus } from "./slidesSlice";

export interface scriptsState {
  script: any;
  loading: boolean;
  loadingPage: boolean;
  saveScript: string;
  textareaDocText: string;
  scriptsList: any[];
  scriptsLength: number;
  user: any;
  scriptStatus: string;
  inpurError: string;
  exportError: string;
  addedText: string;
  currentSelectionData: any;
}

const initialState: scriptsState = {
  script: {},
  loading: false,
  loadingPage: false,
  saveScript: "",
  textareaDocText: "",
  scriptsList: [],
  scriptsLength: 0,
  user: {},
  scriptStatus: "",
  inpurError: "",
  exportError: "",
  addedText: "",
  currentSelectionData: null,
};

export const getUserInfo = createAsyncThunk(
  "scripts/getUserInfo",
  async (token: string, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      const res = await getUser(token);
      dispatch(endLoading());
      return res;
    } catch (error) {
      dispatch(endLoading());
      console.log(error);
    }
  },
);

export const sendScriptToAI = createAsyncThunk(
  "scripts/sendScriptToAI",
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    dispatch(startLoadingPage());
    dispatch(startLoading());
    let id = "";

    try {
      token && (await decreaseCredit(token));
      const res = await createScript(body, token);
      id = await res?.doc?.id.toString();
    } catch (error) {
      dispatch(endLoading());
      dispatch(endLoadingPage());
      console.log(error);
    }

    if (id.length > 0) {
      try {
        let result = {
          status: "",
        } as any;

        const delay = (ms: number) => {
          return new Promise((resolve) => setTimeout(resolve, ms));
        };

        const loop = async () => {
          while (result.status !== "ok") {
            try {
              result = await getScript(id, token);

              if (result.message === "Error") {
                const resTwo = await createScript(body, token);
                id = await resTwo?.doc?.id.toString();
              }
            } catch (error) {
              dispatch(endLoading());
              dispatch(endLoadingPage());
              return;
            }

            await delay(10000);
          }

          if (result.status === "ok") {
            dispatch(endLoading());
            dispatch(endLoadingPage());
            dispatch(changeSidebarStatus(false));
            return result;
          }
        };

        return loop();
      } catch (error) {
        dispatch(endLoading());
        dispatch(endLoadingPage());
        console.log(error);
      }
    } else {
      dispatch(endLoading());
      dispatch(endLoadingPage());
    }
  },
);

export const regText = createAsyncThunk(
  "scripts/regText",
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    dispatch(startLoadingPage());
    dispatch(startLoading());
    try {
      const res = await regenScript(body, token);
      console.log("resREG", res);
      dispatch(endLoading());
      dispatch(endLoadingPage());
      return res?.text;
    } catch (error) {
      dispatch(endLoading());
      dispatch(endLoadingPage());
      console.log(error);
    }
  },
);

export const saveDoc = createAsyncThunk(
  "scripts/saveDoc",
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    try {
      const res = await resaveScript(body, token);
    } catch (error) {
      console.log(error);
    }
  },
);

export const getAllDocs = createAsyncThunk(
  "scripts/getAllDocs",
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    dispatch(startLoading());
    try {
      const res = await getScriptsList(body, token);

      dispatch(endLoading());
      return {
        res: res[0],
        length: res[1],
      };
    } catch (error) {
      dispatch(endLoading());
      console.log(error);
    }
  },
);

export const deleteDoc = createAsyncThunk(
  "scripts/deleteDoc",
  async (data: any, { rejectWithValue, dispatch }) => {
    const { body, token } = data;
    try {
      dispatch(delectDocFromList(body?.docId));
      await deleteScript(body, token);
    } catch (error) {
      console.log(error);
    }
  },
);

export const scriptsSlice = createSlice({
  name: "scripts",
  initialState,
  reducers: {
    updateScript: (state, action) => {
      state.script = action.payload;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    setError: (state, action) => {
      state.inpurError = action.payload;
    },
    endLoading: (state) => {
      state.loading = false;
    },
    startLoadingPage: (state) => {
      state.loadingPage = true;
    },
    endLoadingPage: (state) => {
      state.loadingPage = false;
    },
    setTextareaDocText: (state, action) => {
      state.textareaDocText = action.payload;
    },
    cleanScript: (state) => {
      state.script = {};
    },
    setExportError: (state, action) => {
      state.exportError = action.payload;
    },
    changeScriptStatus: (state, action) => {
      state.scriptStatus = action.payload;
    },
    clearAddedText: (state) => {
      state.addedText = "";
    },
    clearUser: (state) => {
      state.user = {};
    },
    updateScriptList: (state, action) => {
      const { id, title } = action.payload;
      state.scriptsList = state.scriptsList?.map((a: any) => {
        if (a.id === id) {
          return {
            ...a,
            title,
          };
        } else {
          return a;
        }
      });
    },
    scriptSaveStatus: (state, action) => {
      state.saveScript = action.payload;
    },
    delectDocFromList: (state, action) => {
      state.scriptsList = state.scriptsList.filter(
        (a: any) => +a.id !== +action.payload,
      );
      state.scriptsLength = state.scriptsLength - 1;
    },
    updateCurrentSelection: (state, action) => {
      state.currentSelectionData = action.payload;
    },
    getScriptForEdit: (state, action) => {
      state.script = action.payload;
      state.loading = false;
    },
    updText: (state, action) => {
      state.addedText = "\n" + action.payload;

      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendScriptToAI.fulfilled, (state, action: any) => {
        state.script = action.payload;
      })
      .addCase(regText.fulfilled, (state, action: any) => {
        state.script = {
          ...state.script,
          text: action.payload,
          style: "",
        };
      })
      .addCase(getAllDocs.fulfilled, (state, action: any) => {
        const { res, length } = action.payload;
        state.scriptsList = res;
        state.scriptsLength = length;
      })
      .addCase(getUserInfo.fulfilled, (state, action: any) => {
        state.user = action.payload;
      });
  },
});

export const {
  updateScript,
  startLoading,
  endLoading,
  setTextareaDocText,
  cleanScript,
  updateScriptList,
  scriptSaveStatus,
  delectDocFromList,
  startLoadingPage,
  endLoadingPage,
  changeScriptStatus,
  setError,
  setExportError,
  clearAddedText,
  updateCurrentSelection,
  getScriptForEdit,
  updText,
  clearUser
} = scriptsSlice.actions;

export default scriptsSlice.reducer;
