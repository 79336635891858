import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import PresStyles from './PresStyles/PresStyles';

import icon from '../../../../images/triangleIcon.svg';
import lock from '../../../../images/lock.svg';

import './PresentationSelectItem.scss';

type Props = {
  prompt: any,
  resArray: any,
  setResArray: (arg: any) => void,
  index: number
}

export default function PresentationSelectItem({ prompt, resArray, setResArray, index }: Props) {
  const navigate = useNavigate();
  
  const [openInput, setOpenInput] = useState(true);
  const [openStyle, setOpenStyle] = useState(false);
  const [itemObject, setItemObject] = useState<any>({
    value: '',
    style: null,
    save: false
  });

  useEffect(() => {
    const newArr = resArray.map((obj: any, i: number) => {
      if (i === index) {
        return itemObject
      } else {
        return obj;
      }
    })
    setResArray(newArr);
  }, [itemObject]);

  return (
    <div className="PresentationSelectItem">
      {prompt?.isLocked && (
        <div className="PresentationSelectItem__lock">
          <img
            className="PresentationSelectItem__lock-img"
            src={lock} 
            alt="lock"
          />
          <button
            type="button"
            className="PresentationSelectItem__lock-btn"
            onClick={() => navigate('/payment')}
          >
            Upgrade
          </button>
        </div>
      )}
      <p className="PresentationSelectItem__title">{prompt?.second_title}</p>
      <div 
        className="PresentationSelectItem__box"
        // style={{
        //   marginTop: window.location.pathname === '/script' ? '24px' : 0
        // }}
      >
        <div className="PresentationSelectItem__label">
          <button
            type="button"
            className="PresentationSelectItem__label-btn"
            onClick={() => setOpenInput(!openInput)}
          >
            <img 
              src={icon} 
              alt="triangle" 
              className={classNames("PresentationSelectItem__img", {
                "PresentationSelectItem__img--active": openInput
              })}
            />
            <p className="PresentationSelectItem__label-text">Content</p>
          </button>
        </div>
        <div 
          className="PresentationSelectItem__dropdown"
          style={{
            display: openInput ? 'block' : 'none'
          }}
        >
          <label htmlFor="value" className="PresentationSelectItem__input-label">
            {prompt?.prompt?.question}
            {/* {window.location.pathname === '/slide' 
             ? 'Provide a presentation on the topic of'
             : 'Provide a document on the topic of'} */}
            <input
              disabled={prompt?.isLocked}
              className="PresentationSelectItem__input" 
              id="value" 
              type="text"
              value={itemObject?.value}
              onChange={e => setItemObject({...itemObject, value: e.currentTarget.value})}
            />
          </label>
        </div>
      </div>
      {window.location.pathname === '/slide' && (
        <div className="PresentationSelectItem__box">
          <div className="PresentationSelectItem__label">
            <button
              type="button"
              className="PresentationSelectItem__label-btn"
              onClick={() => setOpenStyle(!openStyle)}
            >
              <img 
                src={icon} 
                alt="triangle" 
                className={classNames("PresentationSelectItem__img", {
                  "PresentationSelectItem__img--active": openStyle && !prompt?.isLocked
                })}
              />
              <p className="PresentationSelectItem__label-text">Style</p>
            </button>
          </div>
          <div 
            className="PresentationSelectItem__dropdown"
            style={{
              display: (openStyle && !prompt?.isLocked) ? 'block' : 'none'
            }}
          >
            <PresStyles itemObject={itemObject} setItemObject={setItemObject} prompt={prompt?.default_options}/>
          </div>
        </div>
        )}
    </div>
  )
}
