import pencil from './toolbar-icons/edit-2.svg';
import size from './toolbar-icons/16.svg';
import fontFamily from './toolbar-icons/Font.svg';
import bold from './toolbar-icons/bold.svg';
import italic from './toolbar-icons/italic.svg';
import underline from './toolbar-icons/underline.svg';
import alignJustify from './toolbar-icons/align-justify.svg';
import smile from './toolbar-icons/smile.svg';
import alignCenter from './toolbar-icons/align-center.svg';
import alignLeft from './toolbar-icons/align-left.svg';
import alignRight from './toolbar-icons/align-right.svg';
import undo from './toolbar-icons/rotate-ccw.svg';
import redo from './toolbar-icons/rotate-cw.svg';


export const docEditorToolbar = {
  options: [
    'inline',
    'fontSize',
    'fontFamily',
    'textAlign',
    'colorPicker',
    'list',
    // 'emoji',
    'history',
  ],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: [
      'bold',
      'italic',
      'underline',
      // 'strikethrough',
      // 'monospace',
    ],
    bold: { icon: bold, className: undefined },
    italic: { icon: italic, className: undefined },
    underline: { icon: underline, className: undefined },
    // strikethrough: { icon: strikethrough, className: undefined },
    // monospace: { icon: monospace, className: undefined },
    // superscript: { icon: superscript, className: undefined },
    // subscript: { icon: subscript, className: undefined },
  },
  blockType: {
    inDropdown: true,
    options: [
      'Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code',
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontSize: {
    icon: size,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontFamily: {
    icon: fontFamily,
    options: [
      'Montserrat','Roboto', 'Arial', 'Georgia', 'Impact',
      'Tahoma', 'Times New Roman', 'Verdana',
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
    left: { icon: alignLeft, className: undefined },
    center: { icon: alignCenter , className: undefined },
    right: { icon: alignRight, className: undefined },
    justify: { icon: alignJustify , className: undefined },
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered'],
    // unordered: { icon: unordered, className: undefined },
    // ordered: { icon: ordered, className: undefined },
    // indent: { icon: indent, className: undefined },
    // outdent: { icon: outdent, className: undefined },
  },
  colorPicker: {
    icon: pencil,
    className: "demo-color",
    component: undefined,
    popupClassName: undefined,
    colors: [
      'rgb(26,188,156)',
      'rgb(84,172,210)',
      'rgb(44,130,201)',
      'rgb(147,101,184)',
      'rgb(71,85,119)',
      'rgb(204,204,204)',
      'rgb(65,168,95)',
      'rgb(0,168,133)',
      'rgb(61,142,185)',
      'rgb(41,105,176)',
      'rgb(85,57,130)',
      'rgb(40,50,78)',
      'rgb(0,0,0)',
      'rgb(247,218,100)',
      'rgb(251,160,38)',
      'rgb(235,107,86)',
      'rgb(226,80,65)',
      'rgb(163,143,132)',
      'rgb(239,239,239)',
      // 'rgb(255,255,255)',
      'rgb(250,197,28)',
      'rgb(243,121,52)',
      'rgb(209,72,65)',
      'rgb(184,49,47)',
      'rgb(124,112,107)',
      'rgb(209,213,216)',
    ],
  },
  // emoji: {
  //   icon: smile,
  //   className: undefined,
  //   component: undefined,
  //   popupClassName: undefined,
  //   emojis: [
  //     '😀', '😁', '😂', '😃', '😉', '😋', '😎',
  //     '😍', '😗', '🤗', '🤔', '😣', '😫', '😴',
  //     '😌', '🤓', '😛', '😜', '😠', '😇', '😷',
  //     '😈', '👻', '😺', '😸', '😹', '😻', '😼',
  //     '😽', '🙀', '🙈', '🙉', '🙊', '👼', '👮',
  //     '🕵', '💂', '👳', '🎅', '👸', '👰', '👲',
  //     '🙍', '🙇', '🚶', '🏃', '💃', '⛷', '🏂',
  //     '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴',
  //     '👫', '💪', '👈', '👉', '👉', '👆', '👇',
  //     '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊',
  //     '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇',
  //     '🐥', '🐸', '🐌', '🐛', '🐜', '🐝', '🍉',
  //     '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰',
  //     '🍾', '🍷', '🍸', '🍺', '🌍', '🚑', '⏰',
  //     '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨',
  //     '🌩', '⛄', '🔥', '🎄', '🎈', '🎉', '🎊',
  //     '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈',
  //     '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
  //     '✅', '❎', '💯',
  //   ],
  // },
  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['undo', 'redo'],
    undo: { icon: undo, className: undefined },
    redo: { icon: redo, className: undefined },
  },
};