import React from 'react';
import './WhiteButton.scss';

type Props = {
  text: string,
  action: any,
  img?: any
}

export default function WhiteButton({text, img, action}: Props) {
  return (
    <div
      className={text === "Exported to One Drive" 
        ? "WhiteButton WhiteButton--active" 
        : "WhiteButton"}
      onClick={action}
    >
      {img && <img src={img} alt="folder" />}
      {text}
    </div>
  )
}
