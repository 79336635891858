import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

import { AppDispatch } from '../../../redux/store';
import { setTextareaDocText } from '../../../redux/slices/scriptsSlice';
import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton';

import './LowBalanseModal.scss';

export default function LowBalanseModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  return (
    <div className="LowBalanseModal">
      <div className="LowBalanseModal__wrapper">
        <div
          className="LowBalanseModal__close"
          onClick={() => {
            if (window.location.pathname === '/slide') {
              navigate('/slides');
              dispatch(setTextareaDocText(""));
            } else {
              navigate('/scripts');
              dispatch(setTextareaDocText(""));
            }
          }}
        >
          <CloseIcon
            sx={{
              color: '#5A6861'
            }}
          />
        </div>
        <p>You have no credits!</p>
        <ConfirmButton
          text={"Buy now"}
          action={() => navigate('/payment')}
        />
      </div>
    </div>
  )
}
