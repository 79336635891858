import React, { useRef, useEffect, useState } from 'react';
import SmallLoader from '../Loader/SmallLoader/SmallLoader';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

import './TextAreaChat.scss';
import mic from '../../images/mic-black.svg';
import send from '../../images/send.svg';
import micListening from '../../images/mic-listening.svg';

interface TextAreaChatProps {
  onSubmit: (message: string) => void;
}

const TextAreaChat: React.FC<TextAreaChatProps> = ({ onSubmit }) => {
  const { loading} = useSelector(
    (state: RootState) => state.trainerBot
  );
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { transcript, resetTranscript, listening } = useSpeechRecognition(); 
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const message = inputValue.trim();
    if (message) {
      onSubmit(message);
      setInputValue('');
      SpeechRecognition.stopListening();
      resetTranscript();
    }
  };

  const handleMicClick = () => {
    if (!listening) {
      resetTranscript();
      SpeechRecognition.startListening({continuous: true});
    } else {
        SpeechRecognition.stopListening();
        resetTranscript();
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  useEffect(() => {
    if (transcript && listening) {
      setInputValue(transcript);
    }  
 
  }, [transcript, listening]);

  return (
    <div className="text-area-chat-container">
      <div className="text-area-chat">
          <textarea
            ref={textareaRef}
            value={inputValue}
            onKeyDown={handleKeyDown}
            onChange={(event) => setInputValue(event.target.value)}
            placeholder="Typing here..."
            className="text-area-chat__textarea"
          />
        <div className="text-area-chat__button-container">
          <div
            className={`text-area-chat__button left-button ${listening ? 'active' : ''}`}
            onClick={handleMicClick}
          >   
              {listening ? <img src={micListening} className='mic-listening' alt="mic-listening" /> : <img src={mic} alt="mic" />}
            
          </div>
          <div className="text-area-chat__button right-button" onClick={handleSubmit}>
            <img src={send} alt="send" />
          </div>
        </div>
        
      </div>
        <div className='text-area-chat-container__loader-container'>
          {loading && <SmallLoader/>}
        </div>
    </div>
  );
};

export default TextAreaChat;
