import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import shielt from '../../../images/shield-check.svg';
import lock from '../../../images/lock.svg';
import lockTail from '../../../images/lockTail.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTemplates } from '../../../redux/slices/slidesSlice';
import { cleanScript, getUserInfo } from '../../../redux/slices/scriptsSlice';
import { getCookie } from '../../../utils/CookieHelper';
import { RootState } from '../../../redux/store';
import Loader from '../../Loader/Loader';
import compas from '../../../images/compas.svg';
import { checkCredits, getCredits } from '../../../api/scriptsApi';
import './DocsSection.scss';

export default function DocsSection() {
  const [searchParams] = useSearchParams() || '';
  const sessionId = searchParams.get('session_id') || '';
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const token = getCookie('jwtToken');

  const [showTooltip, setShowTooltip] = useState<number | null>(null);
  
  const { tamplates, loading, } = useSelector((state: RootState) => state.slides);

  useEffect(() => {
    dispatch(cleanScript());
    token && dispatch(getAllTemplates({
      body: {
        isScript: true
      },
      token
    }));
    token && dispatch(getUserInfo(token));
    const foo = async (tok: string) => { 
      await checkCredits(tok);
    };
    token && foo(token);
  }, [token])

  useEffect(() => {
    const foo = async (id: string, tok: string) => { 
      await getCredits(id, tok);
    };
    (sessionId && token) && foo(sessionId, token);
  }, [sessionId, token]);
  
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="DocsSection">
          <h2 className="DocsSection__title">Ai Script Templates</h2>
          <div className="DocsSection__wrapper">
            <div className="DocsSection__row">
              {tamplates?.map((a: any, i: number) => (
                <div
                  key={a?.id}
                  className="DocsSection__btn"
                  onClick={() => {
                    if (!a?.isLocked) {
                      searchParams.set('template', `${a?.id}`);

                      navigate(`/script?${searchParams.toString()}`, { replace: true });
                    }
                  }}
                  onMouseEnter={() => setShowTooltip(a?.id)}
                  onMouseLeave={() => setShowTooltip(null)}
                >
                  {a?.isLocked && <div className="DocsSection__lock-wrapper">
                      <img
                        className="DocsSection__lock-img"
                        src={lock} 
                        alt="lock"
                      />
                      {showTooltip === a?.id && <div className="DocsSection__lock-tooltip">
                        <img className="DocsSection__lock-tooltip-tail" src={lockTail} alt="tail" />
                        <h3 className="DocsSection__lock-tooltip-title">Upgrade for Pro Plan</h3>
                        <p className="DocsSection__lock-tooltip-text">Access top-tier locked templates with our Pro Plan and create stunning content effortlessly</p>
                      </div>}
                      <button
                        type="button"
                        className="DocsSection__lock-btn"
                        onClick={() => navigate('/payment')}
                      >
                        Upgrade
                      </button>
                    </div>}
                  <img 
                    className="ocsSection__icon" 
                    src={a?.image ? a?.image[0]?.url : compas}
                    alt="img"
                  />
                  <p>{a?.category}</p>
                </div>
              ))}
              <div
                className="DocsSection__btn"
                onClick={() => {
                  navigate('/scriptsArchive');
                }}
              >
                <img src={shielt} alt="img" />
                Your Saved <br /> Scripts
              </div>
            </div>
          </div>
        </div> 
      )}
    </>
  )
}
