import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import './PayPlan.scss';
import smallLogo from '../../images/whiteLogo.png';
import { plans } from '../../api/paymentPlans';
import { useNavigate } from 'react-router-dom';
import { getFreePlan, sendStripe } from '../../api/scriptsApi';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../../utils/CookieHelper';
import { getUserInfo } from '../../redux/slices/scriptsSlice';
import { RootState } from '../../redux/store';
import ConfirmButton from '../../components/Buttons/ConfirmButton/ConfirmButton';

export default function PayPlan() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [selectPlan, setSelectPlan] = useState(1);
  const token = getCookie('jwtToken');

  const { user } = useSelector((state: RootState) => state.scripts);
  const { tamplates } = useSelector((state: RootState) => state.slides);
  
  useEffect(() => {
    token && dispatch(getUserInfo(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (user) {
      if (((user?.quantity && user?.isFreeTrialActive) || user?.isPlan) && !tamplates.length) {
        navigate('/slides');
      }   
    }
  }, [user]);

  return (
    <div className="PayPlan">
      <img src={smallLogo} alt="logo" />
      {user?.isFreeTrialActive && (
        <div className="PayPlan__skip-btn">
          <ConfirmButton text={"Skip"} action={() => navigate('/slides')} />
        </div>
      )}

      <h2 className="PayPlan__title">WELCOME TO THE Ai (R)EVOLUTION!</h2>
      <div className="PayPlan__subtitle">
        Once you use Landdai, you’ll never go back, this is the future of corporate learning & development.
      </div>
      <div className="PayPlan__selector">
        <div
          className={classNames('PayPlan__selector-btn', {
            'PayPlan__selector-btn--select': selectPlan === 1
          })}
          onClick={() => setSelectPlan(1)}
        >
          Monthly
        </div>
        <div
          className={classNames('PayPlan__selector-btn', {
            'PayPlan__selector-btn--select': selectPlan === 2
          })}
          onClick={() => setSelectPlan(2)}
        >
          Yearly <span>25%</span> OFF
        </div>
        <div
          className={classNames('PayPlan__bg', {
            'PayPlan__bg--select': selectPlan === 2
          })}
        ></div>
      </div>
      <div
        className="PayPlan__main"
        style={{
          flexDirection: !user?.isFreeTrialActive ? 'row' : 'row-reverse'
        }}
      >
        {plans?.map((plan: any, i: number) => (
          <div
              className={classNames("PayPlan__box", {
                "PayPlan__box--select": i === 1
              })}
              key={plan?.quantity}
              style={{
                display: (user?.isFreeTrialActive && i === 0) ? 'none' : 'flex'
              }}
            >
              {i === 1 && 
                <div className="PayPlan__selection">
                  Best Value
                </div>
              }
              <h2 className="PayPlan__box-title">{plan?.title}</h2>
              <p className="PayPlan__sub">{plan?.sub}</p>
              <div className="PayPlan__price">
                <span>{selectPlan === 1 ? plan?.priceText?.split(' ')[0] : plan?.priceYear?.split(' ')[0]}</span>
                {" " + (selectPlan === 1 ? plan?.priceText?.split(' ').slice(1).join(' ') : plan?.priceYear?.split(' ').slice(1).join(' '))}
              </div>
              <div className="PayPlan__texts">
                {plan?.texts.map((text: string) => (
                  <div
                    className="PayPlan__text"
                    key={text}
                  >
                    <div
                      style={{
                        color: i === 1 ? '#000' : 'inherit'
                      }}
                    >
                      <CheckRoundedIcon sx={{
                        fontSize: 20
                      }} />
                    </div>
                    <p>{text}</p>
                  </div>
                ))}
              </div>
              <div
                className="PayPlan__btn"
                onClick={async() => {
                  if (i === 0) {
                    if (token) {
                      await getFreePlan(token);
                    }
                    navigate('/slides');
                  } else {
                    if (user && token) {
                      const body = {
                        priceId: selectPlan === 1 ? plan?.priceId : plan?.priceIdYearly,
                        quantity: plan?.quantity,
                        email: user?.email,
                      };

                      const link = await sendStripe(body, token);
                      //@ts-ignore
                      if (window.Rewardful?.affiliate) {
                        //@ts-ignore
                        window.Rewardful.convert({ email: user?.email })
                      }
                      window.location.href = link?.url;
                    };
                  }
                }}
              >
                {plan?.btnText}
              </div>
            </div>
        ))}
      </div>
    </div>
  )
}
