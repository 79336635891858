import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { AppDispatch, RootState } from '../../../../redux/store';
import backIcon from '../../../../images/arrow-left.svg';
import blackRefresh from '../../../../images/blackRefresh.svg';
import { 
  changeSlideStatus, 
  deleteBrandLogo, 
  deleteSlide, 
  endLoading,
  getAllPrompts, 
  presentationSaveStatus,
  setPresForEdit,
  startLoading,
  updateName 
} from '../../../../redux/slices/slidesSlice';
import TextEditor from '../../../TextEditor/TextEditor';
import AiGenerateModal from '../../../Modals/AiGenerateModal/AiGenerateModal';
import { sendSlideToGoogle } from '../../../../utils/sender';
import TitleInput from './TitleInput/TitleInput';
import CloseModal from '../../../Modals/CloseModal/CloseModal';
import SlidesList from './SlidesList/SlidesList';
import RemoveModal from '../../../Modals/RemoveModal/RemoveModal';
import SaveButton from '../../../Buttons/SaveButton/SaveButton';
import RegenerateButton from '../../../Buttons/RegenerateButton/RegenerateButton';
import ExportModal from '../../../Modals/ExportModal/ExportModal';
import AiTextareaModal from '../../../Modals/AiTextareaModal/AiTextareaModal';
import DropdownExport from '../../../DropdownExport/DropdownExport';
import { getCookie } from '../../../../utils/CookieHelper';
import { invertLine } from '../../../../utils/colorHelper';
import Loader from '../../../Loader/Loader';
import './SlideSection.scss';
import { setExportError } from '../../../../redux/slices/scriptsSlice';
import { useSocket } from '../../../../pages/Home/Home';

export default function SlideSection() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const token = getCookie('jwtToken');
  const [searchParams] = useSearchParams() || '';
  const getTemplate = searchParams.get('template') || '';
  const editId = searchParams.get('edit') || '';
  const rediePath = getCookie('path');

  const {
    presStyle,
    loadingPage,
    slideList,
    savePresentation,
    loading,
    presentationId,
    presName,
    prompts,
    slideStatus,
  } = useSelector((state: RootState) => state.slides);

  const [modal, setModal] = useState(true);
  const [textareaModal, setTextareaModal] = useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [exportModal, setExportModal] = useState("");
  const [selectSlide, setSelectSlide] = useState<any>(null);
  const [showDeleteLogo, setShowDeleteLogo] = useState(false);
  const messagesEndRef = useRef<any>(null);
  const newSocket = useSocket();

  useEffect(() => {
    if (editId && !slideList.length) {
      dispatch(startLoading());
      newSocket?.emit('get-presentation-by-id', {
        presentation_id: editId
      });

      newSocket?.on('get-presentation-by-id:end', (data: any) => {
        dispatch(setPresForEdit(data.presentation));
      });
    }
  }, [editId]);

  const scrollToBottom = () => {
    const domNode = messagesEndRef.current;

    if (domNode) {
      domNode.scrollTop = domNode.scrollHeight;
    }
  };

  useEffect(() => {
    if (slideStatus === 'add' && !loading) {
      scrollToBottom();
      setSelectSlide(slideList[slideList.length - 1]);
      dispatch(changeSlideStatus(''))
    };
  }, [slideStatus, loading]);

  useEffect(() => {
    const func = async() => {
      const res = await sendSlideToGoogle(presName || 'go', slideList, "azure", presStyle);
      dispatch(endLoading());
      if (res) {
        dispatch(setExportError(res));
      } else {
        setExportModal('send');
      }
      document.cookie = "path=";
    }
    if (rediePath) {
      func();
    }
  }, []);

  useEffect(() => {
    if (!slideList?.length) {
      const names = prompts?.map((a: any) => {
        if (window.location.pathname === '/slide') {
          if (a?.second_title) {
            if (!a?.isScript) {
              return a?.second_title;
            }
          } else {
            return "";
          }
        } else {
          if (a?.second_title) {
            if (a?.isScript) {
              return a?.second_title;
            }
          } else {
            return "";
          }
        }
      })?.filter((b: any) => b?.length > 0);

      if (names) {
        names[0] && dispatch(updateName(names[0]));
      }
    }
  }, [prompts, slideList]);

  useEffect(() => {
    if (token && getTemplate) {
      const obj = {
        body: {
          isScript: false,
          id: getTemplate,
        },
        token,
      };
      dispatch(getAllPrompts(obj));
    };
  }, [token, getTemplate]);

  useEffect(() => {
    savePresentation?.length && setCloseModal(true);
  }, [savePresentation]);

  useEffect(() => {
    if (slideList?.length > 0) {
      if (!selectSlide) {
      setSelectSlide(slideList[0]);
      } else {
        setSelectSlide(slideList.find((a: any) => a.id === selectSlide?.id));
      }
    };
  }, [slideList]);

  const removeSlide = () => {
    dispatch(deleteSlide(selectSlide));
    setRemoveModal(false);
  };

  const savePres = async () => {
    setExportModal("save");
    const objStyleText = await sendSlideToGoogle(presName, slideList, 'save', presStyle)
    const obj = {
      text: objStyleText,
      presentation_id: `${presentationId}`
    };

    const presObj = {
      id: presentationId,
      title: presName,
      default_options: presStyle,
      slides: slideList,
    }

    newSocket?.emit('update-presentation-style', obj);
    newSocket?.emit('update-presentation-by-id', presObj);
  };

  return (
    <>
      {loading && (
        <Loader page={loadingPage} />
      )}
        <div 
          className="SlideSection"
          style={{
            opacity: loading ? 0 : 1
          }}
        >
          <div className="SlideSection__left">
            <div className="SlideSection__back-section">
              <img
                className="SlideSection__back-btn"
                src={backIcon}
                alt="back"
                onClick={() => setCloseModal(true)}
              />
              <TitleInput />
            </div>
            <div className="SlideSection__slides-list-container">
              <SlidesList
                slideList={slideList}
                setSelectSlide={setSelectSlide}
                setRemoveModal={setRemoveModal}
                setTextareaModal={setTextareaModal}
                messagesEndRef={messagesEndRef}
              />
            </div>
          </div>
          <div className="SlideSection__window-btns">
                <DropdownExport
                  presName={presName}
                  slideList={slideList}
                  presStyle={presStyle}
                  setExportModal={setExportModal}
                />
                <SaveButton
                  text={"Save"}
                  action={savePres}
                />
              </div>
          <div className="SlideSection__right">
            <div 
              className="SlideSection__window"
              style={{
                backgroundColor: presStyle?.format_options === 'Bold' ? presStyle?.color_palette : '#fff',
                backgroundImage: presStyle?.background_image ? `url('${presStyle?.background_image}')` : 'none',
              }}
            >
                {(selectSlide?.title || selectSlide?.text) && <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {((!selectSlide?.text && !selectSlide?.image) 
                  || presStyle?.format_options === "Minimalistic"
                  || (presStyle?.format_options === "Modern" && !selectSlide?.image)) && (
                    <div
                      className="SlideSection__modern-line"
                      style={{
                        backgroundColor: presStyle?.format_options === "Bold"
                        ? invertLine(presStyle?.color_palette)
                        : presStyle?.color_palette
                      }}
                    />
                  )}
                  <div
                    className="SlideSection__window-block"
                    style={{
                      gap: selectSlide?.text?.includes('\t') ? '0' : '20px',
                      alignItems: selectSlide?.text ? 'flex-start' : 'center',
                      justifyContent: selectSlide?.text ? 'flex-start' : 'center',
                      maxHeight: '100%',
                      overflow: 'auto'
                    }}
                  >
                    {selectSlide?.title && <TextEditor
                      selectSlide={selectSlide}
                      setSelectSlide={setSelectSlide}
                      type={"title"}
                      isImage={!!selectSlide?.image}
                    />}
                    {selectSlide?.text && <TextEditor
                      selectSlide={selectSlide}
                      setSelectSlide={setSelectSlide}
                      type={"text"}
                      isImage={!!selectSlide?.image}
                    />}
                    <div
                      className="SlideSection__img-refresh-btn"
                      onClick={() => setTextareaModal("text")}
                    >
                      <img
                        className="SlideSection__refresh-img"
                        src={blackRefresh}
                        alt="refresh"
                      />
                    </div>
                    {(presStyle?.brand_logo && selectSlide?.isLogo) && (
                      <div 
                        className="SlideSection__brand-wrapper-logo"
                        onMouseEnter={() => setShowDeleteLogo(true)}
                        onMouseLeave={() => setShowDeleteLogo(false)}
                      >
                        {showDeleteLogo && <div
                          className="SlideSection__delete-icon"
                          onClick={() => dispatch(deleteBrandLogo({
                            body: {
                              id: selectSlide?.id
                            },
                            token
                          }))}
                        >
                          <CloseIcon
                            sx={{
                              fontSize: 13,
                              fontWeight: 'bold',
                              color: '#5A6861'
                            }}
                          />
                        </div>}
                        <img 
                          src={presStyle?.brand_logo} 
                          alt="logo"
                          className="SlideSection__brand-logo"
                        />
                      </div>
                    )}
                  </div>
                </div>}
              {selectSlide?.image && <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  }}
                >
                  {presStyle?.format_options === "Modern" && <div 
                    className="SlideSection__vertical-line"
                    style={{
                      backgroundColor: presStyle?.color_palette
                    }}
                  ></div>}
                  <div className="SlideSection__window-block SlideSection__window-block--image"
                    style={{
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={selectSlide?.image}
                      alt="img"
                      style={{
                        width: (!selectSlide?.title || !selectSlide?.text) ? '50%' : '100%'
                      }}
                    />
                    <div
                      className="SlideSection__img-refresh-btn"
                      onClick={() => setTextareaModal("img")}
                    >
                      <img
                        className="SlideSection__refresh-img"
                        src={blackRefresh}
                        alt="refresh"
                      />
                    </div>
                  </div>
                </div>}
            </div>
          </div>
          <div className="SlideSection__regen-btn">
            <RegenerateButton text={"Regenerate Slide"} action={() => setTextareaModal("updateSlide")} />
          </div>
          {(modal && !slideList?.length) && <AiGenerateModal setModal={setModal} />}
          {textareaModal?.length > 0 && <AiTextareaModal
              path={textareaModal}
              setModal={setTextareaModal}
              selectSlide={selectSlide}
              genImage={presStyle?.format_options !== 'Minimalistic'}
            />
          }
          {closeModal && <CloseModal
            action={async() => {
              await savePres();
              savePresentation?.length ? navigate(savePresentation) : navigate('/slides');
              dispatch(presentationSaveStatus(''));
            }}
            closeNoSave={() => {
              savePresentation?.length ? navigate(savePresentation) : navigate('/slides');
              dispatch(presentationSaveStatus(''));
            }}
            close={() => {
              setCloseModal(false);
              savePresentation?.length && dispatch(presentationSaveStatus(''));
            }}
            text={"Would you like to save your changes before you leave?"}
          />}
          {removeModal && <RemoveModal
            action={removeSlide}
            close={setRemoveModal}
            text={"Are you sure you want to remove this slide?"}
          />}
          {exportModal?.length > 0 && <ExportModal text={exportModal} close={() => setExportModal("")} />}
        </div> 
    </>
  )
}
