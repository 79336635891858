import React from 'react';
import './ConfirmButton.scss'

type Props = {
  action: (arg: any) => void,
  text: string,
  disable?: boolean,
}

export default function ConfirmButton({action, text, disable}: Props) {
  return (
    <div
      className={`ConfirmButton ${disable && 'ConfirmButton--disable'}`}
      onClick={action}
    >
      {text}
    </div>
  )
}
