import React from 'react';
import classNames from "classnames";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../images/LanddaiLogo.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { changeSidebarStatus, presentationSaveStatus, setFixSidebarStatus} from '../../redux/slices/slidesSlice';
import { scriptSaveStatus } from '../../redux/slices/scriptsSlice';
import './SideBar.scss';

export default function SideBar() {
  const { openSidebar: sidebar, fixSidebar} = useSelector((state: RootState) => state.slides);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const goToPage = (path: string) => {
    if (location.pathname === '/slide') {
      dispatch(presentationSaveStatus(path));
    } else if (location.pathname === '/script') {
      dispatch(scriptSaveStatus(path));
    } else {
      navigate(path);
    }
  }

  const handleOpen = () => {
    if (!fixSidebar) {
      dispatch(changeSidebarStatus(true))
    }
  };

  const handleClose = () => {
    if (!fixSidebar) {
      dispatch(changeSidebarStatus(false))
    }
  };
  
  return (
    <div 
      className="SideBar"
      style={{
        width: sidebar || fixSidebar ? '234px' : '94px',
      }}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      <p className="SideBar__beta">BETA</p>
      <img
        className="SideBar__logo"
        src={logo}
        alt="logo"
      />
      <div className="SideBar__wrap">
      <div
          className={classNames("SideBar__nav-btn", {
            "SideBar__nav-btn--long": sidebar || fixSidebar,
            "SideBar__nav-btn--active": location.pathname === '/slides' || location.pathname === '/slide' || location.pathname === '/slidesArchive',
          })}
          onClick={() => goToPage('/slides')}
        >
          <div className={classNames("SideBar__nav-pres", {
            "SideBar__nav-pres--long": sidebar || fixSidebar,
            "SideBar__nav-pres--active": location.pathname === '/slides' || location.pathname === '/slide' || location.pathname === '/slidesArchive',
          })}></div>
          <p>
            Slide Decks
          </p>
        </div>
        <div
          className={classNames("SideBar__nav-btn", {
            "SideBar__nav-btn--long": sidebar || fixSidebar,
            "SideBar__nav-btn--active": location.pathname === '/scripts' || location.pathname === '/script' || location.pathname === '/scriptsArchive',
          })}
          onClick={() => goToPage('/scripts')}
          onMouseEnter={handleOpen}
        >
          <DescriptionOutlinedIcon
            style={{
              fontSize: 28
            }}
          />
          <p>
            Scripts
          </p>
        </div>
        <div
          className={classNames("SideBar__nav-btn", {
            "SideBar__nav-btn--long": sidebar || fixSidebar,
            "SideBar__nav-btn--active": location.pathname === '/chat',
          })}
          onClick={() => goToPage('/chat')}
        >
          <div className={classNames("SideBar__nav-chat", {
            "SideBar__nav-chat--long": sidebar,
            "SideBar__nav-chat--active": location.pathname === '/chat',
          })}></div>
          <p>
            Ministry GPT
          </p>
        </div>
        <div
          className={classNames("SideBar__nav-btn", {
            "SideBar__nav-btn--long": sidebar || fixSidebar,
            "SideBar__nav-btn--active": location.pathname === '/analytics',
          })}
          onClick={() => goToPage('/analytics')}
        >
          <div className={classNames("SideBar__nav-an", {
            "SideBar__nav-an--long": sidebar,
            "SideBar__nav-an--active": location.pathname === '/analytics',
          })}></div>
          <p>
            Courses
          </p>
        </div>
      </div>
      <div
        className="SideBar__line"
        style={{
          width: sidebar || fixSidebar ? '166px' : '74px',
          marginLeft: sidebar || fixSidebar ? '22px' : '0'
        }}
      ></div>
      <div className="SideBar__wrap">
        <div
          className={classNames("SideBar__nav-btn", {
            "SideBar__nav-btn--long": sidebar || fixSidebar,
            "SideBar__nav-btn--active": location.pathname === '/learn',
          })}
          onClick={() => window.open('https://landdai.com/blog')}
        >
          <SchoolOutlinedIcon
            style={{
              fontSize: 28
            }}
          />
          <p>
            Ai Training
          </p>
        </div>
        <div
          className={classNames("SideBar__nav-btn", {
            "SideBar__nav-btn--long": sidebar || fixSidebar,
            "SideBar__nav-btn--active": location.pathname === '/settings',
          })}
          onClick={() => goToPage('/settings')}
        >
          <PersonOutlineOutlinedIcon
            style={{
              fontSize: 28
            }}
          />
          <p>
            Account
          </p>
        </div>
      </div>
      <div className="SideBar__settings-btn">
        <div
          className={classNames("SideBar__nav-btn SideBar__nav-btn--bottom", {
            "SideBar__nav-btn--long": sidebar || fixSidebar,
            "SideBar__nav-btn--bottom": true,
          })}
        >
          <div className="SideBar__togle">
            <div
              onClick={() => dispatch(setFixSidebarStatus(!fixSidebar))}
              className={classNames("SideBar__togle-select", {
                "SideBar__togle-select--disable": !fixSidebar
              })}
            >
              <div className="SideBar__togle-select-btn"></div>
            </div>
            <p>
              {fixSidebar ? 'Hide sidebar' : 'Show sidebar'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
