import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { store, persistor, RootState } from './redux/store';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import PrivateRoutes from './utils/PrivateRoutes';
import LoginPage from './pages/LoginPage/LoginPage';
import Home from './pages/Home/Home';
import './App.scss';
import PayPlan from './pages/PayPlan/PayPlan';
import PayPlanLimit from './pages/PayPlanLimit/PayPlanLimit';
import { updatePlanLimited } from './api/userApi';
import { getCookie, setCookie } from './utils/CookieHelper';

const AppWraper = () => {
  const { user, loading } = useSelector((state: RootState) => state.scripts);
  const planLimited = getCookie('planLimited') == '1' || user?.plan_limited === 1;

  return (
    <>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<Home />} path="/*" />
            <Route element={<PayPlan />} path="/payment" />
            <Route element={<PayPlanLimit />} path="/pricing" />
            <Route element={<Navigate to={((user?.quantity && user.isFreeTrialActive) || user.isPlan) ? "slides" : planLimited ? "/pricing" : "/payment"} replace/>} path=""/>
          </Route>
          <Route element={<LoginPage />} path="/login" />
        </Routes>
      </Router>
    </>
  )
}

function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <div className="App">
            <AppWraper />
          </div>
      </PersistGate>
    </Provider>
  );
};

export default App;
