import React, { useState } from 'react';
import lockTail from '../../../images/lockTail.svg';
import './RegenerateButton.scss'

type Props = {
  action: (arg: any) => void,
  text: string,
  disabled?: boolean
}

export default function RegenerateButton({action, text, disabled}: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div
      className={`RegenerateButton ${disabled && "RegenerateButton--disable"}`}
      onClick={action}
      onMouseEnter={() =>setShowTooltip(true)}
      onMouseLeave={() =>setShowTooltip(false)}
    >
      {(text === "Change with AI" && showTooltip) && (
        <div className="RegenerateButton__tooltip">
          <img className="RegenerateButton__tooltip-tail" src={lockTail} alt="tail" />
          <p className="RegenerateButton__tooltip-text">To activate this button, highlight the text you want to enrich. Once you’ve selected your text, click the button to generate additional content and see the results.</p>
        </div>
      )}
      {text}
    </div>
  )
}
