import React from 'react'
import ConfirmButton from '../../Buttons/ConfirmButton/ConfirmButton';
import CloseIcon from '@mui/icons-material/Close';
import './MaxLengthModal.scss';

type Props = {
  text: string,
  close: () => void
};

export default function MaxLengthModal({
  text,
  close }: Props) {

  return (
    <div className="MaxLengthModal">
      <div className="MaxLengthModal__wrapper">
        <div
          className="MaxLengthModal__close"
          onClick={close}
        >
          <CloseIcon
            sx={{
              color: '#5A6861'
            }}
          />
        </div>
        <p>{text}</p>
        <div className="MaxLengthModal__btns">
          <div
            className="MaxLengthModal__btn"
            onClick={close} 
          >
            Ok
          </div>
        </div>
      </div>
    </div>
  )
}
