import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import backIcon from '../../../images/arrow-left.svg';
import { clearUser, getUserInfo } from '../../../redux/slices/scriptsSlice';
import { RootState } from '../../../redux/store';
import { deleteCookie, getCookie } from '../../../utils/CookieHelper';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import Loader from '../../Loader/Loader';
import './AccountSection.scss';
import { updatePlanLimited } from '../../../api/userApi';
import { cleanPresentation } from '../../../redux/slices/slidesSlice';

export default function AccountSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const token = getCookie('jwtToken') || '';
  const planLimited = getCookie('planLimited') == '1';

  const { user, loading } = useSelector((state: RootState) => state.scripts);

  useEffect(() => {
    token && dispatch(getUserInfo(token));
  }, [token]);

  const handleLogOut = () => {
    dispatch(clearUser());
    dispatch(cleanPresentation());
    deleteCookie('jwtToken');
    deleteCookie('googleToken');
    deleteCookie('planLimited');
    navigate('/login');
  };
  
  return (
    <>
      {loading ? <Loader /> :
        (
          <div className="AccountSection">
            <div className="AccountSection__header">
              <div className="AccountSection__back-btn">
                <img
                  className="AccountSection__back-btn-img"
                  src={backIcon}
                  alt="back"
                  onClick={() => navigate(-1)}
                />
                <div
                  className="AccountSection__back-btn-text"
                >
                  {`${user?.firstName} ${user?.lastName}`}
                </div>
              </div>
              <SaveButton text={"Log out"} action={handleLogOut} />
            </div>
            <div className="AccountSection__main">
              <p className="AccountSection__text">Subscriptions</p>
              <div className="AccountSection__box">
                <p className="AccountSection__info-text">Your plan <span>{user?.plan_name}</span></p>
                {/* <p className="AccountSection__info-text">
                  <span>Period</span>: {new Date(user?.start_date)?.toISOString()?.split('T')[0]} - {new Date(user?.end_date)?.toISOString()?.split('T')[0]}
                </p> */}
                <p className="AccountSection__info-text">Credits left: <span>{user?.quantity}</span></p>
                <div className="AccountSection__btn">
                  <SaveButton text={"Upgrade Plan"} action={() => navigate(user?.plan_limited === 1 || planLimited ? '/pricing' : '/payment')} />
                </div>
              </div>
            </div>
          </div>
        )
      }

    </>
  )
}
