import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import GoogleButton from '../../components/Buttons/GoogleButton/GoogleButton';
import Loader from '../../components/Loader/Loader';
import bigLogo from '../../images/LanndaiBigLogo.png';
import smallLogo from '../../images/whiteLogo.png';
import './LoginPage.scss';

export default function LoginPage() {
  const [loader, setLoader] = useState(false);

  const handleClick = () => {
    setLoader(true);
    window.location.href = 'https://app.churchbrain.ai/api/auth';
    // window.location.href = 'http://localhost:8000/api/auth';
  };

  return (
    <div className="LoginAccount">
      {loader ? (
        <Loader />
      ) : (
        <>
          <img
            src={smallLogo}
            alt="small-logo"
            className="LoginAccount__white-logo"
          />
          <div className="LoginAccount__block LoginAccount__block--left">
            <h1 className="LoginAccount__title">Welcome to <span>Churchbrain AI</span></h1>
            <p className="LoginAccount__text">The Future of Corporate Learning & Development.</p>
            <div className="LoginAccount__google-btn">
              <GoogleButton onClick={() => handleClick()} />
            </div>
          </div>
          <div className="LoginAccount__block">
            <img src={bigLogo} alt="logo" />
          </div>  
        </>
      )}
    </div>
  )
}
